.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    position: absolute;
    width: 410px;
    left: 40px;
    bottom: 80px;
    border-radius: 4px;
    box-sizing: border-box;
    padding-top: 36px;
    background: var(--intro-modal-bg);
    color: var(--intro-modal-color);

    @media (max-height: 320px) {
        scale: 0.9;
        bottom: 60px;
    }

    @media (max-height: 300px) {
        scale: 0.8;
        bottom: 50px;
    }
}

.title {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: var(--desk-font-size-2-4);
    line-height: 130%;
    margin-bottom: 12px;
}

.subtitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-5);
    line-height: 130%;
    margin-bottom: 20px;
}

.icons_container {
    display: flex;
    justify-content: center;
    height: 82px;
}

.icon {
    display: flex;
    background-repeat: no-repeat;
    height: 50px;
    width: 50px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    margin: 0 8px;
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.closeIcon {
    display: flex;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    background-image: var(--intro-close-icon-url);

    &:hover {
        background-image: url("/images/close_cross_pale.svg");
    }
}