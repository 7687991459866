.container {
    position: absolute;
    bottom: 72px;

    display: flex;
    flex-direction: column;
    align-items: center;

    right: 21.5rem;
    width: 378px;
    height: 432px;

    padding: 12px;
    box-sizing: border-box;
    border-radius: 1.3rem;
    background: #fff;
    box-shadow: 0px 8px 24px 0px rgba(28, 31, 37, 0.2);

    @media (max-width: 1366px) {
        right: 13.5rem;
        bottom: 58px;
    }

    @media (max-width: 1366px) {
        right: 13.5rem;
        bottom: 58px;
    }

    .item_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 354px;
        height: 56px;
        border-radius: 8px;
        border: 1px solid #89909c;
        margin-top: 10px;
        padding: 5px;

        .title {
            font-weight: 700;
            font-size: var(--desk-font-size-1-6);
            line-height: 130%;
            color: #343841;
        }
        .description {
            font-weight: 500;
            font-size: var(--desk-font-size-1-3);
            line-height: 130%;
            color: #89909c;
            text-align: center;
        }
    }

    .popup_header {
        display: flex;
        width: 100%;
        height: 12px;

        justify-content: end;
        align-items: center;

        .close_icon {
            height: 10px;
            display: flex;
            align-items: center;
            position: relative;
            color: #000;
            width: 10px;
            cursor: pointer;

            &::before {
                display: inline-block;
                font-size: var(--desk-font-size-1-6);
                content: "\00d7";
                margin-top: -2px;
            }
        }
    }
}
.backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111;
}
