.swiper_container {
    width: 230px;
    position: relative;
}

.higlighter {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    top: 78px;
}

.higlighter_h {
    position: absolute;
    height: 1px;
    width: 60px;
    top: 0;
    left: 40px;

    &:before {
        content: " ";
        position: absolute;
        left: 0;
        right: auto;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #4c515e;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
    }
    &:after {
        content: " ";
        position: absolute;
        left: 0;
        top: -40px;
        right: auto;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #4c515e;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
    }
}

.higlighter_m {
    position: absolute;
    height: 1px;
    width: 60px;
    top: 0px;
    left: 134px;

    &:before {
        content: " ";
        position: absolute;
        left: 0;
        right: auto;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #4c515e;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
    }
    &:after {
        content: " ";
        position: absolute;
        left: 0;
        top: -40px;
        right: auto;
        display: block;
        width: 100%;
        height: 1px;
        background-color: #4c515e;
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
    }
}
.higlighter_dots {
    position: absolute;
    width: 5px;
    bottom: 10px;
    left: 115px;
    font-size: 16px;
}
