.table_wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;

    &.fullScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: var(--footer-height);
        z-index: 99;
        background: #1c1f25;
    }
}

.drawer_header_wrp {
    width: 56px;
    height: 28px;
    position: absolute;
    top: -28px;
    right: 0;
    text-align: right;
    background: var(--open-trades-table-close-container-bg);

    .drawer_header_btns_wrp {
        display: flex;
        align-items: center;
        padding: 6px;
        gap: 12px;

        .drawer_header_btn {
            display: flex;
            width: 28px;
            height: 28px;

            border: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;

            &.close_icon_dark,
            &.close_icon_light {
                background-image: url("/images/close-cross-grey.svg");
                width: 16px;
                height: 16px;

                &:hover {
                    background-image: url("/images/close-cross-lightgrey.svg");
                }
            }

            &.close_icon_light {
                &:hover {
                    background-image: url("/images/close_cross_white.svg");
                }
            }

            &.maximize_icon_dark,
            &.maximize_icon_light {
                background-image: url("/images/maximize-grey.svg");
                width: 16px;
                height: 16px;

                &:hover {
                    background-image: url("/images/maximize-hover.svg");
                }
            }

            &.maximize_icon_light {
                &:hover {
                    background-image: url("/images/maximize-white.svg");
                }
            }
        }
    }
}

.fullScreenHeader {
    position: relative;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px 12px 24px;
    background: #101115;
    color: #fff;
    font-weight: 600;
    font-size: var(--desk-font-size-1-8);
    line-height: 150%;

    .closeIcon {
        position: absolute;
        top: 5px;
        right: 12px;
    }

    :global(.MuiButtonBase-root) {
        &:hover {
            background-color: transparent;
            color: #a7aebb;
        }
    }

    :global(.MuiSvgIcon-root) {
        font-size: 22px;
    }
}

.overlayed {
    pointer-events: none;
    z-index: 1301;
}

.table_body_wrap {
    padding: 0 16px;
    height: var(--trades-tables-height);
    background-color: var(--open-trades-table-body-wrp-bg);
    border-top: 2px solid var(--open-trades-table-body-wrp-top-border);

    overflow-y: hidden;

    .fullScreen & {
        height: 100%;
    }

    @media (max-width: 1100px) {
        padding: 0;
    }
}

.table_body {
    height: calc(100% - 51px);
    overflow: auto;

    &::-webkit-scrollbar-thumb {
        visibility: hidden;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    }
}

.table_row,
.table_header_row {
    display: grid;
    grid-template-columns: 7% 9% 7% 6% 5% 7% 8% 10% 9% 9% 7% 8% 8%;

    &.pending {
        grid-template-columns: 7% 9% 7% 6% 5% 7% 7% 8% 7% 7% 10% 6% 7% 7%;
    }
}

.table_header_row {
    border-bottom: 1px solid #89909c;
    margin-right: 6px; // scrollbar offset

    > div {
        flex: 1;
    }
}

.table_row {
    align-items: center;
    justify-items: center;
    border-top: 1px solid var(--open-trades-table-row-border);

    &:first-child {
        border-top: none;
    }

    &:hover {
        background: var(--open-trades-table-row-bg);
    }

    > div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        flex: 1;

        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: var(--desk-font-size-1-5);
        text-align: center;
        color: var(--open-trades-table-row-color);

        @media (max-width: 1300px) {
            font-size: var(--desk-font-size-1-0);
        }

        @media (max-width: 1100px) {
            font-size: var(--desk-font-size-1-0);
        }
    }
}

.table_item_cell_volume {
    @media (max-width: 1300px) {
        max-width: 20px;
    }
}

.table_header_cell_wrp {
    padding: 24px 6px 8px;

    @media (max-width: 1100px) {
        padding: 18px 2px 4px;
    }

    .table_header_cell {
        .header_label {
            cursor: pointer;
        }
    }
}

.table_header_cell {
    color: var(--open-trades-table-header-color);
    font-weight: 700;
    font-size: var(--desk-font-size-1-4);
    line-height: 130%;
    text-align: center;
    white-space: nowrap;
    text-transform: uppercase;

    @media (max-width: 1480px) {
        font-size: var(--desk-font-size-1-1);
    }

    @media (max-width: 1300px) {
        font-size: var(--desk-font-size-1-1);
    }

    @media (max-width: 1100px) {
        font-size: var(--desk-font-size-1-1);
        white-space: normal;
    }

    @media (max-width: 1024px) {
        font-size: var(--desk-font-size-1);
    }
}

.table_item_cell_time_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 90px;

    &.hiddenDate {
        opacity: 0;
    }
}

.table_item_cell_expirationDate {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;

    .expirationDateInput {
        width: 20px;
        border: none;
    }

    .clockIcon {
        min-width: 20px;
        height: 20px;
        background-size: 14px;
        background-repeat: no-repeat;
        background-image: var(--clock-icon-url);
        background-position: center;

        &.expDateHover {
            background-image: var(--clock-icon-hover-url);
        }
    }

    .calendarIcon {
        cursor: pointer;
        min-width: 20px;
        height: 20px;
        background-size: 14px;
        background-repeat: no-repeat;
        background-image: var(--datepicker-icon-url);
        background-position: center;
    }

    &:hover {
        color: var(--color);

        .calendarIcon {
            background-image: var(--datepicker-icon-hover-url);
        }
    }
}

.table_item_cell_date {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: var(--desk-font-size-1-5);

    @media (max-width: 1300px) {
        font-size: var(--desk-font-size-1-1);
    }

    @media (max-width: 1100px) {
        font-size: var(--desk-font-size-1-1);
    }
}

.table_item_cell_time {
    display: flex;
    align-items: center;
    font-size: var(--desk-font-size-1);
    font-weight: 700;

    @media (max-width: 1300px) {
        font-size: var(--desk-font-size-09);
    }

    @media (max-width: 1100px) {
        font-size: var(--desk-font-size-08);
    }

    img {
        width: 13px;
        margin-right: 2px;
    }
}

.table_item_cell_direction {
    img {
        margin-left: 4px;
    }
}

.cell_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: var(--desk-font-size-1-5);
    font-weight: 600;
    height: 23px;
    padding: 2px 16px;
    color: var(--trades-table-btn-color);
    border: 2px solid var(--trades-table-btn-border);
    border-radius: 32px;
    cursor: pointer;

    &:hover {
        background: var(--trades-table-btn-hover-bg);
        border-color: var(--trades-table-btn-hover-border);
        color: var(--trades-table-btn-hover-color);
    }

    @media (max-width: 1100px) {
        padding: 2px 8px;
        font-size: var(--desk-font-size-1-2);
    }
}

.close_btn,
.delete_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    border: 2px solid var(--trades-table-btn-border);
    color: var(--trades-table-btn-color);
    height: 34px;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: var(--desk-font-size-1-5);
    &.disabled {
        opacity: 0.7;
        &:hover {
            background: none;
            color: var(--trades-table-btn-color);
            border: 2px solid var(--trades-table-btn-border);
        }
    }
    &:hover {
        background: var(--trades-table-btn-hover-bg);
        border-color: var(--trades-table-btn-hover-border);
        color: var(--trades-table-btn-hover-color);
    }

    @media (max-width: 1100px) {
        padding: 2px 8px;
    }
}

.profit {
    color: var(--profit);
}

.loss {
    color: var(--loss);
}
