.notice {
    &__iconContainer {
        display: flex;
        margin: 48px auto 16px;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 72px;
        background: var(--trades-section-notice-icon-mbl-bg);
        border-radius: 16px;
    }

    .iconMarket,
    .iconMarket_light {
        width: 40px;
        height: 40px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/images/market.svg");
    }

    .iconMarket_light {
        background-image: url("/images/market_black.svg");
    }

    .title {
        font-weight: 500;
        font-size: var(--mbl-font-size-xl);
        line-height: 24px;
        text-align: center;
        color: #9098AD;
    }
}

.notice.searchNotice {
    .header {
        font-weight: 600;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        color: var( --trades-section-notice-title-mbl-color);
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .iconNoResultFound,
    .iconNoResultFound_light {
        width: 36px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/images/iconsNoResultFound.svg");
    }

    .iconNoResultFound_light {
        background-image: url("/images/iconsNoResultFound_black.svg");
    }

    .title {
        font-weight: 600;
        font-size: var(--mbl-font-size-xl2);
        line-height: 32px;
        text-align: center;
        color: var( --trades-section-notice-title-mbl-color);
        margin-bottom: 10px;
        padding: 0 32px;
    }

    .subTitle {
        font-weight: 500;
        font-size: var(--mbl-font-size-lg);
        line-height: 20px;
        text-align: center;
        color: #9098AD;
        padding: 0 32px;
    }
}
