.container {
    display: grid;
    grid-template-columns: 28px 0.8fr 1fr;
    align-items: center;
    background: var(--trades-section-selected-trade-profit-loss-mbl-bg);
    border-radius: 5px;
    padding: 8px 8px 8px 10px;

    .label {
        font-weight: 500;
        font-size: var(--desk-font-size-1-4);
        padding-left: 6px;
        color: var(--color);
    }

    .inputNumber {
        width: 100%;

        input {
            background: var(--trades-section-selected-trade-input-number-mbl-bg);
        }
    }

    .inputNumber_tablet {
        width: 170px;
    }

    &.disabled {
        .label {
            color: var(--trades-section-selected-trade-profit-loss-label-disabled-mbl-color);
        }
    }
}

.container_tablet {
    grid-template-columns: 28px 2fr 0.5fr;
}

.calculatorContainer {
    position: absolute;
    left: 0;
    top: calc((var(--vh, 1vh) * 100) - 230px);
    z-index: 2;
    width: 100%;
}

.checkbox {
    border: 1px solid var(--trades-section-checkbox-mbl-border) !important;
}

.checkbox_disabled {
    border: 1px solid var(--checkbox-border) !important;
}