.container {
    padding: 15px;
    height: calc(100 * var(--vh));
    width: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    bottom: 0;
    background: var(--bg);
    z-index: 6;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 44px;

        .title_dark,
        .title_light {
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: var(--wallet-text-color);
            margin-bottom: 16px;
            margin-top: 25px;
        }

        .title_light {
            color: var(--wallet-text-black-color);
        }

        .attentionInfo_dark,
        .attentionInfo_light {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: var(--wallet-text-lightgrey-color);
            text-align: center;
        }

        .attentionInfo_light {
            color: var(--wallet-text-dark_grey-color);
        }
    }

    .header_tablet {
        margin-bottom: 84px;
    }

    main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        .subtitle_dark,
        .subtitle_light {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: var(--wallet-text-color);
            margin-bottom: 16px;
        }

        .subtitle_light {
            color: #000000;
        }

        .subtitle_tablet {
            margin-bottom: 40px;
        }

        .actionsPanel_dark,
        .actionsPanel_light {
            display: flex;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            gap: 15px;
            margin-bottom: 23px;
            width: 100%;

            .action_dark,
            .action_light {
                height: 120px;
                background: var(--wallet-action-bg);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 1;
                gap: 16px;
                padding: 16px 0px;
                color: var(--wallet-text-color);

                .actionLabel {
                    max-width: 90px;
                    height: 32px;
                    text-align: center;

                    &.single_line {
                        max-width: none;
                    }
                }

                .actionIcon {
                    width: 40px;
                    height: 40px;
                }

                .actionIcon_tablet {
                    width: 83px;
                    height: 83px;
                }
            }

            .action_tablet {
                height: 250px;
                width: 219px;
                padding: 43px 0px;

                .actionLabel {
                    width: 100%;
                }
            }

            .action_light {
                background: var(--wallet-action-bg-light);
                color: var(--wallet-text-black-color);
            }

            .porIcon {
                background-image: var(--wallet-action-panel-por-icon-bg);
            }

            .porIcon_tablet {
                background: var(--wallet-action-panel-por-tablet-icon-bg) no-repeat;
            }

            .poiIcon {
                background-image: var(--wallet-action-panel-poi-icon-bg);
            }

            .poiIcon_tablet {
                background: var(--wallet-action-panel-poi-tablet-icon-bg) no-repeat;
            }

            .podIcon {
                background-image: var(--wallet-action-panel-pod-icon-bg);
            }

            .podIcon_tablet {
                background: var(--wallet-action-panel-pod-tablet-icon-bg) no-repeat;
            }
        }

        .long_text {
            font-size: var(--desk-font-size-1);
        }

        .actionsPanel_light {
            color: var(--wallet-text-black-color);
        }

        .actionsPanel_tablet {
            margin-bottom: 64px;
            gap: 31px;
        }

        .approvementInfo_dark,
        .approvementInfo_light {
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            border-radius: 5px;
            background: #15181D;
            display: flex;
            gap: 14px;
            padding: 16px 15px;
            text-align: left;

            .infoIcon {
                width: 24px;
                height: 24px;
                background: transparent var(--wallet-info-icon) no-repeat center;
                filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(343deg) brightness(100%) contrast(101%);
                padding-right: 17px;
                padding-top: 10px;
            }
        }

        .approvementInfo_light {
            color: var(--wallet-text-color);
            background: var(--wallet-text-dark_grey-color);
        }
    }

    footer {

        .bottomPanel_dark,
        .bottomPanel_light {
            display: flex;
            justify-content: center;
            border: 1.5px solid var(--wallet-text-dark_grey-color);
            border-radius: 5px;
            color: var(--wallet-text-color);
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            gap: 35px;
            padding: 16px 0;
            margin-bottom: 40px;

            .icon {
                width: 24px;
                height: 24px;
            }

            .block {
                display: flex;
                align-items: center;
                gap: 9px;
            }

            .requestBlock {
                .requestIcon_dark {
                    background: transparent var(--wallet-footer-panel-request-icon-bg) no-repeat center;
                }

                .requestIcon_light {
                    background: transparent var(--wallet-action-panel-request-icon-bg-light) no-repeat center;
                }
            }

            .arrowIcon_dark {
                background: transparent var(--wallet-action-panel-arrowRight-icon-bg) no-repeat center;
                background-size: 24px 24px;
            }

            .arrowIcon_light {
                background: transparent var(--wallet-action-panel-arrowRight-icon-bg-light) no-repeat center;
                background-size: 24px 24px;
            }

            .businessDayBlock {
                .businessDayIcon_dark {
                    background: transparent var(--wallet-footer-panel-businessDay-icon-bg) no-repeat center;
                }

                .businessDayIcon_light {
                    background: transparent var(--wallet-action-panel-businessDay-icon-bg-light) no-repeat center;
                }
            }
        }

        .bottomPanel_light {
            border: 1.5px solid #E0E5F0;
            color: var(--wallet-text-black-color);
        }

        .buttomPanel_tablet {
            margin-bottom: 48px;
            gap: 100px;
        }
    }

    .closeIcon_dark,
    .closeIcon_light {
        position: absolute;
        right: 11px;
        top: 19px;
        display: flex;
        height: 26px;
        width: 26px;
        border-radius: 13px;

        &::before {
            content: "";
            display: block;
            margin: auto;
            height: 20px;
            width: 20px;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .closeIcon_dark {
        background: var(--wallet-close-icon-bg);

        &::before {
            background-image: var(--wallet-close-btn-icon);
        }
    }

    .closeIcon_light {
        background: var(--wallet-close-icon-bg-light);

        &::before {
            background-image: var(--wallet-close-btn-icon-light);
        }
    }
}

.container_tablet {
    padding: 15px 24px;
}