.container {
    padding: 8px;
    padding-top: 24px;
    background: var(--date-range-mbl-default-bg);
}

.header {
    position: relative;
    font-weight: 500;
    font-size: var(--mbl-font-size-lg);
    line-height: 24px;
    color: var(--date-range-mbl-default-color);
    padding: 0 8px 16px;
    display: flex;
    justify-content: space-between;

    .label_container {
        display: flex;
        gap: 5px;
        align-items: center;

        .current_year {
            padding-right: 5px;
        }
    }

    .switcher_container {
        display: flex;
        width: 120px;
        height: 24px;
        align-items: flex-end;
        justify-content: flex-end;
        box-sizing: border-box;
        padding-right: 8px;
    }

    .switcher_control {
        display: flex;
        width: 24px;
        height: 24px;
    }

    .switcher_right,
    .switcher_left {
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .switcher_right {
        margin-left: 10px;
    }

    .switcher_left {
        background-image: var(--calendar-drawer-switcher-right-bg);

        &.active {
            background-image: var(--calendar-drawer-switcher-right-bg-active);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
        }
    }

    .switcher_right {
        background-image: var(--calendar-drawer-switcher-left-bg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);

        &.active {
            background-image: var(--calendar-drawer-switcher-left-bg-active);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
    }
}

.daysGrid {
    &__header,
    &__days {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 0 8px;
    }

    &__header {
        border-bottom: 1px solid var(--date-range-mbl-calendar-header-border);
    }

    .dayName {
        font-weight: 600;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        text-align: center;
        color: #9098ad;
        padding: 8px;
    }

    .dayNumber {
        position: relative;
        margin: 4px 0;

        .value {
            position: relative;
            font-weight: 500;
            font-size: var(--mbl-font-size-lg);
            color: #9098ad;
            border-radius: 32px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
        }

        &.firstSelected,
        &.lastSelected {
            .value {
                background: var(--date-range-mbl-calendar-day-active-bg);
                color: var(--date-range-mbl-calendar-day-active-color);
                z-index: 1;
            }

            .dockingMarking {
                position: absolute;
                display: block;
                content: "";
                background-color: var(--date-range-mbl-calendar-day-intermediate-bg);
                width: 50%;
                height: 32px;
                left: 50%;
                top: 0;
            }
        }

        &.lastSelected {
            .dockingMarking {
                left: 0;
            }
        }

        &.intermediate {
            background-color: var(--date-range-mbl-calendar-day-intermediate-bg);

            .value {
                color: var(--date-range-mbl-calendar-day-intermediate-color);
            }
        }
    }
}

.daysContainer {
    height: 200px;
}
