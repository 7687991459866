.month_picker_container {
    margin-top: 87px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 27px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    .row {
        display: flex;
        gap: 26px;
        height: 32px;

        .rowItem {
            width: 65px;
            color: var(--date-range-mbl-default-color);
            display: flex;
            justify-content: center;
            align-items: center;

            &.disabled {
                color: var(--date-range-mbl-disabled-color);
            }

            &.active {
                color: var(--date-range-mbl-calendar-day-active-color);
                background: var(--date-range-mbl-default-color);
                border-radius: 40px;
            }
        }

    }
}

.year_picker_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    gap: 25px;
    margin-top: 40px;
    color: var(--date-range-mbl-default-color);

    .row {
        display: flex;
        gap: 5px;

        .rowItem {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 65px;
            height: 32px;

            &.active {
                color: var(--date-range-mbl-calendar-day-active-color);
                background: var(--date-range-mbl-default-color);
                border-radius: 40px;
            }
        }

    }
}