.container {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 205px;
    width: 100vw;
}

.profitLossContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    background: var(--trades-section-selected-trade-actions-mbl-bg);
    padding: 16px 15px;
}

.actionContainer {
    padding: 0 15px 11px 15px;
    background: var(--trades-section-selected-trade-actions-mbl-bg);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    height: 100%;

    .btn {
        height: 40px;
        width: 100%;
        border-radius: 8px;
        background-color: transparent;
        font-weight: 600;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        text-align: center;
        color: #ffffff;
    }

    .btnCancel {
        border: 1px solid var(--app-modal-close-trade-btn-transparent-border-color);
        color: var(--app-modal-close-trade-btn-transparent-color);
        background-color: var(--app-modal-close-trade-btn-bg);

        &.touched {
            background-color: var(--trades-section-selected-trade-btn-cancel-touched-mbl-bg);
            border: 1px solid transparent;
            color: var(--trades-section-selected-trade-btn-cancel-touched-mbl-color);
        }
    }

    .btnApply {
        background: var(--trades-section-selected-trade-btn-apply-mbl-bg);
        color: var(--trades-section-selected-trade-btn-apply-mbl-color);
        border: 0px;

        &.touched {
            background-color: var(--trades-section-selected-trade-btn-apply-touched-mbl-bg);
        }
    }

    .btnApply_disabled {
        background: var(--trades-section-selected-trade-btn-apply-disabled-mbl-bg);
        color: var(--trades-section-selected-trade-btn-apply-disabled-mbl-color);
        border: none;
        opacity: 1;
    }
}