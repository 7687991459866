.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 6px;
    row-gap: 8px;
    padding-top: 1rem;

    .graph_container {
        background-color: lightgray;
    }

    .infoBlock {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 56px;
        padding: 0.5rem;
        background: var(--trades-section-list-item-info-mbl-bg);
        border-radius: 5px;
        font-weight: 500;
        font-size: var(--desk-font-size-1);
        line-height: 13px;
        text-align: center;
        color: var(--trades-section-list-item-info-mbl-color);
        overflow: hidden;
        transition: height 0.3s, opacity 0.3s;

        &__value {
            line-height: 14px;
            margin-top: 4px;
            font-weight: 600;
            text-overflow: ellipsis;
        }
    }

    .infoBlock_hidden {
        height: 0;
        opacity: 0;
    }
}

.profitColor {
    color: #16bb6f;
}

.lossColor {
    color: #f25555;
}