.container {
    position: relative;
    width: 69px;
    height: 36px;
    background: #4b4f56;
    border-radius: 36px;
    padding: 3px;
    display: flex;
    cursor: pointer;

    @media (max-width: 1366px) {
        width: 52px;
        height: 32px;
    }

    @media screen and (max-width: 1024px) {
        width: 60px;
        height: 30px;
    }

    .thumb {
        position: absolute;
        left: 3px;
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background: #ffffff;
        transition: transform 0.3s ease-in-out;

        &.on {
            transform: translateX(33px);
        }

        @media (max-width: 1366px) {
            width: 26px;
            height: 26px;

            &.on {
                transform: translateX(20px);
            }
        }

        @media screen and (max-width: 1024px) {
            width: 25px;
            height: 25px;

            &.on {
                transform: translateX(14px);
            }
        }
    }
}