.drawerWrp {
    height: 550px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: transparent !important;
    top: calc((var(--vh, 1vh) * 100) - 550px) !important;
}

.overlay {
    position: relative;
    border-radius: 16px 16px 0px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--calendar-drawer-overlay-bg);
}

.overlay_tablet {
    width: 375px;
    bottom: 50%;
    left: calc(50% - 375px / 2);
    box-shadow: 0px 1px 8px rgba(28, 31, 37, 0.60413);
    border-radius: 16px 16px 0px 0px;
}

.drawerInnerWrp {
    position: relative;
    display: grid;
    grid-template-rows: 56px 267px 124px 64px;
    align-items: flex-start;
    justify-items: flex-start;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    width: 100%;
    height: 549px;
    box-sizing: border-box;
    padding: 24px 15px 19px 15px;
    background-color: var(--calendar-drawer-inner-wrp-bg);
    touch-action: none;
    justify-content: center;
}

.divider {
    position: absolute;
    height: 1px;
    width: calc(100vw - 14px);
    top: 104px;
    left: 7px;
    background-color: var(--calendar-drawer-inner-wrp-divider);
}

.calendar_container {
    border: none;
}

.calendar_container {
    width: 100%;
    border: none;
    background-color: white;

    button {
        color: #9098ad;
    }

    [class*="react-calendar__tile--active"] {
        color: #fff !important;
        background-color: #000 !important;
    }

    [class*="react-calendar__tile--now"] {
        color: #000;
        background-color: #f4f6fb;
    }
}

.calendar_container {
    background-color: var(--calendar-container-bg);

    button {
        color: var(--calendar-container-btn-color);
        background-color: transparent;
        width: 32px;
        height: 32px;

        flex: none !important;
        outline: none;
        border: none;

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        border-radius: 50px;
    }

    button:disabled {
        opacity: 1;
        color: #9098ad;
    }

    [class*="react-calendar__tile--active"] {
        color: var(--calendar-container-tile-active-color) !important;
        background-color: var(--calendar-container-tile-active-bg) !important;
    }

    [class*="react-calendar__tile--now"] {
        color: var(--calendar-container-tile-now-color);
        background-color: var(--calendar-container-tile-now-bg);
    }

    [class*="react-calendar__month-view__weekdays"] {
        display: flex;
        justify-content: space-between;

        gap: 15px;
        width: 100%;
        height: 24px;
        flex-wrap: nowrap;
        margin-bottom: 7px;
    }

    [class*="react-calendar__month-view__weekdays__weekday"] {
        width: 32px;
        height: 12px;
        align-items: center;
        justify-content: center;

        flex: none !important;
        box-sizing: border-box;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #9098ad;
    }

    [class*="react-calendar__month-view__days"] {
        display: grid !important;
        grid-template-columns: 32px 32px 32px 32px 32px 32px 32px;
        width: 100%;
        grid-gap: 8px 19px;
        justify-content: space-between;
        flex-wrap: nowrap;
        color: var(--calendar-container-day-color);

        > abbr {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
}

.day_tile {
    background-color: rebeccapurple;
}

.date_container {
    display: flex;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-6);
    line-height: 24px;
    width: 100%;
    min-width: 345px;
    height: 50px;
    justify-content: space-between;
    color: var(--calendar-drawer-date-container-color);

    .currentDate {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 20px;

        .dateTitle {
            position: relative;

            &.year_picker_open {
                bottom: 1px;
            }
        }
    }
}

.timePicker_wrp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
}

.switcher_container {
    display: flex;
    width: 120px;
    height: 24px;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
}

.switcher_control {
    display: flex;
    width: 24px;
    height: 24px;
}

.switcher_right,
.switcher_left {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.switcher_right {
    margin-left: 6px;
}

.switcher_left {
    background-image: var(--calendar-drawer-switcher-right-bg);

    &.active {
        background-image: var(--calendar-drawer-switcher-right-bg-active);
        transform: rotate(180deg);
    }
}

.switcher_right {
    background-image: var(--calendar-drawer-switcher-left-bg);
    transform: rotate(180deg);

    &.active {
        background-image: var(--calendar-drawer-switcher-left-bg-active);
        transform: rotate(0deg);
    }
}

.controls_container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 19px;
    padding: 0 15px;

    &.mobile {
        width: 100%;
    }

    &.tablet {
        align-items: center;
        position: absolute;
    }
}

.btn_set,
.btn_cancel {
    display: flex;
    flex: 1;
    height: 40px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-2);
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
}

.btn_set {
    margin-left: 11px;
    color: var(--calendar-btn-set-color);
    background: var(--calendar-btn-set-bg);

    &.disabled {
        background-color: var(--calendar-btn-set-disabled-bg);
        color: var(--calendar-btn-set-disabled-color);
    }

    &.touched {
        background-color: var(--calendar-btn-set-touched-bg);
        color: var(--calendar-btn-set-touched-color);
    }
}

.btn_cancel {
    background: var(--calendar-btn-cancel-bg);
    border: var(--calendar-btn-cancel-border);
    color: var(--calendar-btn-cancel-color);

    &.touched {
        border: 2px solid transparent;
        background-color: var(--calendar-btn-cancel-touched-bg);
        color: var(--calendar-btn-cancel-touched-color);
    }
}
