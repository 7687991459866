.pl_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;

    border-top: 2px solid var(--profit-loss-border-color-mbl);
    background: var(--asset-list-row-bg);
    height: 44px;
    justify-items: center;

    &.tablet {
        padding-top: 4px;
    }
}

.info_block {
    display: flex;
    height: 44px;
    font-family: "Inter";
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: var(--desc-font-size-1);
    line-height: 14px;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: #9098ad;

    &.tablet {
        padding-bottom: 2px;
    }
}

.directionsPanel {
    width: 56px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 5px;

    .directionUp {
        border: 1.5px solid #16BB6F;
        border-radius: 4px;
        width: 100%;
        height: 24px;
        background: var(--profit-loss-directionUp-icon) no-repeat center;
    }

    .directionDown {
        border: 1.5px solid #F25555;
        border-radius: 4px;
        width: 100%;
        height: 24px;
        background: var(--profit-loss-directionDown-icon) no-repeat center;
    }

    .active_sell {
        background: #F25555 var(--profit-loss-directionDown-selected-icon) no-repeat center;
    }

    .active_buy {
        background: #16BB6F var(--profit-loss-directionUp-selected-icon) no-repeat center;
    }

    @keyframes fleshBuyBtnAnimation {
        0% {
            background-color: var(--action-buy);
            color: var(--action-buy);
        }

        100% {
            background-color: transparent;
            color: var(--action-buy);
        }
    }

    .highlightBuy {
        animation-name: fleshBuyBtnAnimation;
        animation-duration: 500ms;
    }

    @keyframes fleshSellBtnAnimation {
        0% {
            background-color: var(--action-sell);
            color: var(--action-sell);
        }

        100% {
            background-color: transparent;
            color: var(--action-sell);
        }
    }

    .highlightSell {
        animation-name: fleshSellBtnAnimation;
        animation-duration: 500ms;
    }
}


.profit_value {
    height: 16px;
    color: var(--profit);
}

.loss_value {
    height: 16px;
    color: var(--loss);
}