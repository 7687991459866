.marginInfoContainer {

    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 15px;
    border-top: 1.5px solid var(--asset-panel-margin-info-tbl-border);
    gap: 16px;

    &.long_text {
        gap: 5px;
    }

    .block {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 90px;
        font-weight: 500;
        font-size: 9px;
        line-height: 14px;

        .label {
            color: var(--asset-panel-margin-info-tbl-color);
            white-space: nowrap;
        }
    }

    .block:nth-child(1) {
        border-right: 1px solid var(--asset-panel-margin-info-tbl-separator-bg);
    }

    .block:nth-child(2) {
        border-right: 1px solid var(--asset-panel-margin-info-tbl-separator-bg);
    }
}
