.container {
    position: absolute;
    width: 360px;
    min-height: 540px;
    background: var(--intro-modal-bg);
    top: 156px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 12px;
    color: var(--intro-modal-color);

    @media (max-height: 775px) {
        top: 70px;
    }

    @media (max-height: 690px) {
        top: 30px;
    }

    @media (max-height: 690px) {
        scale: 0.9;
        top: 0;
    }

    @media (max-height: 570px) {
        scale: 0.8;
        top: -30px;
    }

    @media (max-height: 500px) {
        scale: 0.7;
        top: -60px;
    }

    .content {
        padding: 36px 34px 0 34px;

        .title {
            font-family: var(--main-font-family);
            font-style: normal;
            font-weight: 700;
            font-size: var(--desk-font-size-2-4);
            line-height: 130%;
            text-align: center;
            margin-bottom: 12px;
        }

        .subtitle {
            font-family: var(--main-font-family);
            font-style: normal;
            font-weight: 600;
            font-size: var(--desk-font-size-1-5);
            line-height: 130%;
            text-align: center;
            margin-bottom: 24px;
        }

        .mg_16 {
            margin-bottom: 16px !important;
        }
    }

    .image_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 28px;

        >img {
            max-height: 220px;
        }
    }

    .close {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }

    .stepper_wrapper {}
}

.closeIcon {
    display: flex;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    background-image: var(--intro-close-icon-url);

    &:hover {
        background-image: url("/images/close_cross_pale.svg");
    }
}

.candles_diagram {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    color: var(--intro-fourth-step-chart-description-color);
    font-weight: 600;
    font-size: var(--desk-font-size-1-5);
    line-height: 130%;

    .top_title,
    .bottom_title {
        text-transform: uppercase;
    }

    .candles_container {
        display: flex;
        width: 100%;
        height: 220px;

        .left_desc,
        .right_desc {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            align-items: end;
            padding: 15px 0;
        }

        .right_desc {
            align-items: start;
        }

        .candles_image {
            flex: 0.5;
            background-image: var(--intro-fourth-step-candle-image);
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}