.tableContainer {
    .tableHead {
        :global(.MuiTableCell-head) {
            color: #fff;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid #89909c;
            font-family: var(--main-font-family);
        }
    }

    .tableBody {
        :global(.MuiTableRow-root) {
            height: 48px;
        }

        :global(.MuiTableCell-body) {
            color: #89909c;
            font-size: 13px;
            font-weight: 600;
            font-family: var(--main-font-family);
            border-bottom: 1px solid #343841;
        }

        .tableCellValue {
            &.buy,
            .buy {
                color: #16bb6f;
            }
            &.sell,
            .sell {
                color: #f25555;
            }
        }

        .flexColumn {
            display: flex;
            flex-direction: column;
        }
    }
}
