.dialogWrap {
    :global(.MuiDialog-paper) {
        border-radius: 8px !important;
    }

    :global(.MuiBackdrop-root) {
        background: var(--trading-order-modal-backdrop-bg);
    }
}

.container_dark,
.container_light {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    width: 634px;
    background: #1c1f25;
    border-radius: 8px;
}

.container_light {
    background: #ffffff;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 24px;
    box-sizing: border-box;
    background: #101115;
}

.header_text {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-8);
    line-height: 130%;
    color: #ffffff;
}

.header_close_btn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/images/white_cross.svg");

    &:hover {
        background-image: url("/images/grey_cross.svg") !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 16px 12px 16px;
    width: 100%;
}

.content_wrp {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
}

.content_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12px 0 20px 0;
}

.content_header_column {
    display: flex;
    flex-direction: column;
}

.content_header_title_dark,
.content_header_title_light {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-3);
    line-height: 130%;
    color: #e6ecf1;
}

.content_header_title_light {
    color: #343841;
}

.content_header_subtitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-1);
    line-height: 130%;
    color: #89909c;
}

.form_container {
    display: flex;
    flex-direction: column;
    margin: 20px 0 14px 0;
    width: 100%;
    box-sizing: border-box;

    label {
        cursor: pointer;
        white-space: nowrap;
    }
}

.chart {
    display: flex;
    width: 100%;
    height: 150px;
    background: var(--bg);
    border: 1px solid #89909c;
    border-radius: 6px;
    overflow: hidden;
}

.chart__block {
    border: 0px solid #89909c;
    height: 0px;
    width: 0px;
}

.chart_container {
    display: flex;
}

.actions_container {
    display: grid;
    grid-template-columns: 253px 80px 253px;
}

.counter_container {
    display: flex;
    justify-content: space-between;
    width: 90px;
    height: 24px;
    background: #444854;
    border: 1px solid #89909c;
    box-sizing: border-box;
    border-radius: 2px;
}

.counter_number {
    display: flex;
    width: 34px;
    justify-content: center;
    align-items: center;
}

.counter_action_btns {
    display: flex;
    flex-direction: row;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: var(--desk-font-size-1-3);
    line-height: 130%;
    color: #e6ecf1;
}

.setter_row {
    display: grid;
    align-items: center;
    grid-template-columns: 258px 85px 258px;
    height: 40px;
}

.counter_action_btn {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 100px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0 4px;
    cursor: pointer;

    &.plus {
        background-image: url("/images/plus_action_btn.svg");
    }

    &.minus {
        background-image: url("/images/minus_action_btn.svg");
    }
}

.checkbox_row_dark,
.checkbox_row_light {
    display: grid;
    grid-template-columns: 30px 90px 223px 30px 90px;
    height: 25px;

    label {
        font-size: var(--desk-font-size-1-1);
        font-weight: 600;
        color: #89909c;
        margin-top: 2px;
        margin-left: -5px;
        height: 13px;
    }
}

.checkbox_row_light {
    label {
        color: #343841;
    }
}

.row_text_dark,
.row_text_light {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-1);
    line-height: 130%;
    text-align: center;
    color: #ffffff;
}

.row_text_light {
    color: #343841;
}

.container_submit_btn {
    display: flex;
    height: 60px;
    margin-top: 20px;
    justify-content: center;
    align-items: center;

    .submit_btn_dark,
    .submit_btn_light {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px 49px;
        height: 42px;
        color: #343841;
        background: #ffffff;
        cursor: pointer;
        border-radius: 8px;
        font-weight: 700;
        font-size: var(--desk-font-size-1-4);
        line-height: 130%;

        &:hover {
            background: var(--trading-order-submit-hover-bg_dark);
        }

        &.submit_disabled {
            background: var(--trading-order-submit-disabled-bg);
            color: var(--trading-order-submit-disabled-color);
            cursor: default;
            pointer-events: none;

            &:hover {
                background: var(--trading-order-submit-disabled-bg);
                color: var(--trading-order-submit-disabled-color);
            }
        }

        &.submit_btn_disabled_dark,
        &.submit_btn_disabled_light {
            background: #454853;
            color: #676b76;
            cursor: default;
        }

        &.submit_btn_disabled_light {
            color: #ffffff;
        }
    }

    .submit_btn_light {
        background: #000000;
        color: #ffffff;

        &:hover {
            background: var(--trading-order-submit-hover-bg_light);
        }
    }
}

.plTextInput_dark,
.plTextInput_light {
    border-radius: 2px;
    height: 24px;
    background: #555965;
    border: transparent;
    color: #89909c;
    font-size: var(--desk-font-size-1-1);
    line-height: 130%;
    padding-left: 8px;
    font-weight: 600;
}

.plTextInput_light {
    background: #e6ecf1;
    color: #343841;
    border: 0px;
}

.pipsForm {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--desk-font-size-1-4);
    line-height: 130%;
    width: 100%;
}

.pipsAmountTextInput_dark,
.pipsAmountTextInput_light {
    background: #555965;
    font-weight: 600;
    color: #89909c;
    border-radius: 2px;
    border: transparent;
    height: 24px;
    width: 117px;
    padding-left: 8px;
    line-height: 130%;
}

.pipsAmountTextInput_light {
    background: #e6ecf1;
    border: 0px;
    color: #343841;
}

.pips_margin_active_dark,
.pips_margin_active_light {
    background: var(--trading-order-modal-input-hover) !important;
    color: #e6ecf1;
    font-size: var(--desk-font-size-1-3) !important;
}

.pips_margin_active_light {
    background: #e6ecf1;
    color: #343841;
}

.margin_disabled_dark,
.margin_disabled_light {
    font-size: var(--desk-font-size-1-3);
    line-height: 130%;
    font-size: var(--desk-font-size-1-3) !important;
}

.margin_disabled_light {
    color: #A7AEBB !important;
    border: 1px solid #A7AEBB !important;


}

.margin_active_dark,
.margin_active_light {
    color: #e6ecf1;
    font-size: var(--desk-font-size-1-3) !important;
    font-weight: 700;
}

.margin_active_light {
    color: #343841;
}

.actions_disabled {

    .increase,
    .decrease {
        .disabled {
            border: 1.5px solid black;
        }
    }
}