.years_picker {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
    top: 36px;
    margin-bottom: 40px;
    background: #fff;

    .years_picker__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 60px;

        .header__currentYear {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            margin-bottom: 12px;


            &:hover {
                background-color: rgba(0, 0, 0, 0.12);
                padding: 0 0.5em;
                border-radius: 3px;
                transition: background-color 0.1s linear;
            }

            .backArrowIcon {
                width: 8px;
                height: 6px;
                background: var(--datepicker-arrow-icon-url);
                background-size: 10px;
                background-repeat: no-repeat;
            }
        }

        .header__yearsPeriod {
            color: #89909c;
            font-weight: 600;
            font-size: 11px;
            line-height: 130%;
        }
    }

    .years_picker__content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 54px;
        height: 184px;
        width: 100%;
        background-color: #ffffff;

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;

        .years_picker__years {
            display: flex;
            flex-direction: column;

            .row {
                display: flex;

                .column {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 58px;
                    height: 24px;
                    cursor: pointer;

                    &:hover {
                        background: #dbdbdb;
                        color: #343841;
                        border-radius: 80px;
                    }

                    &.activeYear {
                        background-color: #343841;
                        color: #ffffff;
                        border-radius: 80px;
                    }

                    &.disabled {
                        color: #dbdbdb;
                        cursor: default;
                        pointer-events: none;

                        &:hover {
                            background-color: #fff;
                            color: #dbdbdb;
                        }
                    }
                }
            }
        }

        .years_switcher {
            display: flex;
            height: 20px;
            width: 20px;
            border: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px;

            background-color: #ffff;
            cursor: pointer;

            &.right {
                background-image: url("/images/arrow_left_black.svg");
                transform: rotate(180deg);
            }

            &.left {
                background-image: url("/images/arrow_left_black.svg");
            }

            &.disabled {
                background-image: url("/images/arrow_left_pale.svg");
            }
        }
    }
}

.months_picker {
    position: relative;
    width: 100%;

    .months_picker__header {
        display: flex;
        margin-top: 14px;
        padding: 6px;
        height: 30px;
        justify-content: space-between;
        align-items: center;
        border-bottom: #89909c;

        .months_picker_header_year {
            display: flex;
            align-items: center;
            gap: 4px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.12);
                padding: 0 0.5em;
                border-radius: 3px;
                transition: background-color 0.1s linear;
            }

            .backArrowIcon {
                width: 8px;
                height: 6px;
                background: var(--datepicker-arrow-icon-url);
                background-size: 10px;
                background-repeat: no-repeat;
            }

        }

        .years_switcher {
            display: flex;
            height: 20px;
            width: 20px;
            border: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px;

            &.right {
                background-image: url("/images/arrow_right_black.svg");
                background-color: #fff;
                cursor: pointer;
            }

            &.left {
                background-image: url("/images/arrow_left_black.svg");
                background-color: #fff;
                cursor: pointer;
            }

            &.disabled {
                background-image: url("/images/arrow_left_pale.svg");
            }
        }
    }

    .months_picker__content {
        position: absolute;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 200px;
        width: 100%;

        .row {
            display: flex;

            .column {
                cursor: pointer;
                width: 70px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    background: #dbdbdb;
                    color: #343841;
                    border-radius: 80px;
                }
            }

            .currentMonth {
                background-color: #343841;
                border-radius: 80px;
                color: #ffffff;

                &:hover {
                    background: #dbdbdb;
                    color: 343841;
                }
            }

            .disableMonth {
                color: #dbdbdb;

                &:hover {
                    background-color: transparent;
                    color: #dbdbdb;
                    cursor: inherit;
                }
            }
        }
    }
}