.drawerWrp {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #15181D;
    z-index: 1601;
}

.header {
    position: fixed;
    top: 0;
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 15px 0;
}

.accountInfoWrp {
    display: grid;
    grid-template-columns: 36px 1fr;
    align-items: center;
}

.accountInfoData {
    display: flex;
    flex-direction: column;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-2);
    line-height: 16px;
    /* identical to box height, or 133% */

    /* Grey/Grey - 9098AD */

    color: #9098ad;
}

.accountInfoId {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-6);
    line-height: 24px;
    color: #ffffff;
}

.closeIconWrp {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 8px;
    top: 16px;
    height: 32px;
    width: 32px;
}

.closeIcon {
    background: var(--set-lot-size-mbl-menu-close-icon-bg);
    height: 26px;
    width: 26px;
    border-radius: 13px;
    display: flex;

    &::before {
        margin: auto;
        height: 24px;
        width: 24px;
        content: var(--set-lot-size-mbl-menu-close-icon-url);
    }
}

.accountIcon {
    width: 26px;
    height: 26px;
    background-color: #000000;
    border-radius: 50px;
    background-image: url("/images/accountIcon.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 297px);

    &.tablet {
        height: calc(100vh - 462px);
    }
}

.balanceWrp {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &.tablet {
        margin-top: 90px;
        height: 100%;
        justify-content: center;
    }
}

.balanceAmount {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-4);
    line-height: 48px;
    text-align: center;
    color: #ffffff;
}

.balanceTitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-2);
    line-height: 24px;
    text-align: center;
    color: #9098ad;
    margin-bottom: 8px;
}

.balanceIcon {
    flex: 1;
    width: 120px;
    max-height: 120px;
    margin-bottom: 20px;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;

    &.tablet {
        width: 148px;
        max-height: 148px;
        margin-bottom: 24px;
    }
}

.tradeInfoWrp {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 297px;
    max-height: 297px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 65px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #fff;
    padding: 40px 40px 75px;

    &.tablet {
        height: 462px;
        max-height: 462px;
        gap: 0;
    }
}

.tradeInfoTableColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.tablet {
        justify-content: start;
        gap: 34px;
    }
}

.tradeInfoTableCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.tradeInfoTableCell_title {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-2);
    line-height: 16px;
    text-align: center;
    color: #9098ad;
}

.tradeInfoTableCell_subtitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-6);
    line-height: 20px;
    text-align: center;
    color: #000000;
    height: 20px;
}

.tradePanelItem {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 8px 10px;
    gap: 8px;
    min-width: 44px;
    height: 40px;
    line-height: 20px;
    font-size: var(--desk-font-size-1-6);
    background: var(--account-info-trade-panel-item-bg);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 50%;
}

.svgIcon {
    background-image: var(--account-info-trade-panel-item-img-url);
}

.svgIcon_blackIcon {
    background-image: url("/images/accountIcon_black.svg");
}

.tradePanelItem__expanded__dark,
.tradePanelItem__expanded__light {
    position: relative;
    min-width: 180px;
    background-size: 20px;
    background-position: 12px 50%;
    background-color: #343841;
    box-shadow: 0px 1px 8px rgba(28, 31, 37, 0.60413);
    border-radius: 5px;
}

.tradePanelItem__expanded__light {
    background-color: #ffffff;
    box-shadow: 0px 1px 8px rgba(149, 157, 177, 0.283572);
}

.tradePanelItem_dots {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    right: 4px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: auto;
}

.tradePanelItem_dots {
    background-color: var(--account-info-trade-panel-item-dots-bg);
    background-image: var(--account-info-trade-panel-item-dots-img-url);
}

.accountInfoButtonWrp {
    position: absolute;
    left: 40px;
    display: flex;
    flex-direction: column;
}

.accountInfoTitle__dark,
.accountInfoTitle__light {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-6);
    margin-bottom: 2px;
    line-height: 24px;

    color: #ffffff;
}

.accountInfoTitle__light {
    color: #000000;
}

.accountInfoSubtitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1);
    line-height: 14px;
    color: #9098ad;
}