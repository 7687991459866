.component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 446px;
    height: 100%;
    color: var(--asset-list-search-no-results-color);

    @media (max-width: 1260px) {
        width: 414px;
    }

    .search_not_found_icon {
        width: 72px;
        height: 72px;
        display: block;
        background: var(--asset-list-search-no-results-bg);
        padding: 18px;
        border-radius: 16px;
        margin-bottom: 24px;

        &::before {
            margin: auto;
            height: 36px;
            width: 36px;
            content: var(--asset-list-search-no-results-icon-url);
        }
    }

    .title {
        font-size: var(--desk-font-size-2-2);
        font-weight: 700;
        margin-bottom: 16px;
        line-height: 18px;
    }

    .subtitle {
        line-height: 22px;
        font-size: var(--desk-font-size-1-4);
        font-weight: 500;
        max-width: 86%;
        text-align: center;
    }
}
