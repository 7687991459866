.tradesList {
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;

    //margin-top: 8px;
    &.isClosedTrades {
        height: calc(100% - 30px);
    }
    &::-webkit-scrollbar-thumb {
        height: 10px;
    }

    .tradesItem {
        border: 1px solid var(--trades-section-list-item-mbl-border);
        padding: 0.8rem 1rem 1.6rem 1rem;
        margin-top: 0.8rem;
        border-radius: 5px;

        &_notExpanded {
            max-height: 120px;
        }

        &:last-child {
            margin-bottom: 0.8rem;
        }

        &__header {
            display: grid;
            grid-template-columns: 1fr 98px;
            column-gap: 12px;
            align-items: center;
        }

        .actionContainer {
            display: flex;
            margin-left: auto;

            :not(:first-child) {
                margin-left: 8px;
            }

            .btn {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                background: transparent;
                border: 1px solid var(--trades-section-list-item-btn-mbl-border);

                &.active {
                    background: var(--trades-section-list-item-btn-mbl-active-bg);
                }

                &.touched {
                    background: var(--trades-section-list-item-btn-mbl-active-bg);
                }
            }

            .iconOpen,
            .iconClose,
            .iconEdit,
            .iconDelete,
            .iconOpen_light,
            .iconClose_light,
            .iconEdit_light,
            .iconDelete_light,
            .iconCross,
            .iconCross_light {
                width: 22px;
                height: 22px;
                background-repeat: no-repeat;
                background-position: center;
                margin: auto;
            }

            .iconOpen,
            .iconClose {
                background-image: url("/images/arrow_down.svg");
            }

            .iconOpen_touched {
                background-image: var(--trades-section-list-item-btn-mbl-touched-icon);
            }

            .iconClose_touched {
                // background-image: var(--trades-section-list-item-btn-mbl-active-bg)
            }

            .iconClose {
                background-image: url("/images/arrow_down_black.svg");
                transform: rotate(180deg);
            }

            .iconEdit {
                background-image: url("/images/edit.svg");
            }

            .iconDelete {
                background-image: url("/images/delete.svg");
            }

            .iconCross,
            .iconCross_light {
                width: 10px;
                height: 10px;
            }

            .iconCross {
                background-image: url("/images/x.svg");
            }

            .iconCross_light {
                background-image: url("/images/xBlack.svg");
            }

            .iconOpen_light,
            .iconClose_light {
                background-image: url("/images/arrow_down_black.svg");
            }

            .iconClose_light {
                background-image: url("/images/arrow_down.svg");
                transform: rotate(180deg);
            }

            .iconEdit_light {
                background-image: url("/images/edit_black.svg");
            }

            .iconDelete_light {
                background-image: url("/images/delete_black.svg");
            }
        }
    }

    .collapse_item {
        border: none;
        padding: 0;
        margin-top: 0;
        height: 0;
        transition: 0.5s all ease-out;
    }
}

.containerScrollActive {
    padding-right: 4px;
}
