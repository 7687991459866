.container {
    @media screen and (max-width: 479px) {
        width: calc(100% - 2rem);
        top: 64px;
    }

    position: absolute;
    padding: 16px 12px;
    top: 64px;
    left: 50%;
    transform: translate(-50%, 0);
    // TODO change for common use
    width: calc(100% - 2rem);
    background: var(--app-modal-bg);
    color: var(--app-modal-color);
    box-shadow: var(--app-modal-shadow);
    border-radius: 5px;

    &:focus-visible {
        outline: none;
    }
}
