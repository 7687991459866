.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading_bar_light,
.loading_bar_dark {
    z-index: 999999;
    width: 100px;
    height: 8px;
    background: #e0e5f0;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    &::after {
        content: "";
        width: 58px;
        height: 8px;
        position: absolute;
        background: #4c515e;
        border-radius: 4px;
        transform: translateX(-20px);
        animation: loop 3s ease infinite;
    }
}

.loading_bar_light {
    background: #e0e5f0;

    &::after {
        background: #4c515e;
    }
}

.loading_bar_dark {
    background: #343841;

    &::after {
        background: #f4f6fb;
    }
}

@keyframes loop {
    0%,
    100% {
        transform: translateX(-28px);
    }
    50% {
        transform: translateX(78px);
    }
}

.text_light,
.text_dark {
    margin-top: 8px;
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1);
    line-height: 14px;
    text-align: center;
}

.text_light {
    color: #000000;
}

.text_dark {
    color: #ffffff;
}
