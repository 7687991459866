.container {
    height: calc(100 * var(--vh));
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--set-lot-size-mbl-default-bg);
    color: var(--set-lot-size-mbl-default-color);
    display: flex;
    flex-direction: column;

    &:focus-visible {
        outline: none;
    }

    .header {
        display: flex;
        padding-left: 10px;
        padding-top: 16px;
        padding-bottom: 30px;

        .infoIcon, .infoIcon__light {
            display: flex;
            align-items: center;
            margin-right: 8px;
            filter: invert(100%) sepia(0%) saturate(7423%) hue-rotate(189deg) brightness(112%)
            contrast(99%);
        }

        .infoIcon__light {
            filter: invert(0%) sepia(100%) saturate(7500%) hue-rotate(128deg) brightness(111%) contrast(111%);
        }
    }

    .closeIcon {
        position: absolute;
        right: 11px;
        top: 11px;
        background: var(--set-lot-size-mbl-menu-close-icon-bg);
        height: 26px;
        width: 26px;
        border-radius: 13px;
        display: flex;

        &::before {
            margin: auto;
            height: 25px;
            width: 25px;
            content: var(--set-lot-size-mbl-menu-close-icon-url);
        }
    }
}
