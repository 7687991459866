.container {
    position: fixed;
    height: calc((var(--vh, 1vh) * 100));
    background: var(--system-feedback-mbl-default-bg);
    color: var(--system-feedback-mbl-default-color);
    top: 0;
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: column;
    z-index: 1600;
    padding: 0 15px;

    @media (min-width: 768px) {
        padding: 0 24px;
    }

    .icon {
        margin: 40px auto 8px;
        width: 72px;
        height: 72px;
        background-repeat: no-repeat;
        background-position: center;

        @media (min-width: 768px) {
            margin: 96px auto 9px;
        }
    }

    .title {
        font-weight: 600;
        font-size: var(--desk-font-size-2-4);
        line-height: 32px;
        text-align: center;
        color: var(--system-feedback-mbl-title-bg);
    }

    .description {
        font-weight: 500;
        font-size: var(--desk-font-size-1-6);
        line-height: 20px;
        text-align: center;
        color: var(--system-feedback-mbl-description-color);
        padding: 8px 21px;

        @media (min-width: 768px) {
            max-width: 302px;
            margin: 0 auto;
            padding: 8px 0;
        }
    }

    .btnContainer {
        margin: auto 0 27px;
        display: flex;

        :not(:first-child) {
            margin-left: 16px;

            @media (min-width: 768px) {
                margin-left: 32px;
            }
        }

        @media (min-width: 768px) {
            margin-bottom: 40px;
        }
    }

    .btn {
        height: 48px;
        text-align: center;
        width: 100%;
        font-weight: 600;
        font-size: var(--desk-font-size-1-6);
        line-height: 20px;
        color: var(--system-feedback-mbl-btn-color);
        background: var(--system-feedback-mbl-btn-bg);
        border-radius: 5px;
        border: none;
        padding: 12px;

        &.touched {
            background-color: var(--system-feedback-mbl-pressed-btn-bg);
            color: var(--system-feedback-mbl-pressed-btn-color);
        }
    }

    .btnContainerTwoButtons {
        .btn {
            font-size: var(--desk-font-size-1-2);
        }
    }

    .btnTransparent {
        border: 1.5px solid var(--app-modal-close-trade-btn-transparent-border-color);
        color: var(--app-modal-close-trade-btn-transparent-color);
        background-color: transparent;

        &.touched {
            border: 1.5px solid transparent;
        }
    }
}

.defaultSuccess.orderFeedback {
    .title {
        margin-bottom: 64px;
        color: var(--system-feedback-mbl-title-bg);
    }
}

.marketClosed,
.insufficientFunds {
    .clockClosedIcon {
        background-image: var(--system-feedback-mbl-market-closed-url);
    }
}

.insufficientFunds {
    .fundsIcon {
        background-image: var(--system-feedback-mbl-funds-url);
    }

    .closeIcon {
        position: absolute;
        right: 12px;
        top: 12px;
        background: var(--date-range-mbl-menu-close-icon-bg);
        height: 26px;
        width: 26px;
        border-radius: 13px;
        display: flex;

        &::before {
            margin: auto;
            height: 25px;
            width: 24px;
            content: var(--date-range-mbl-menu-close-icon-url);
        }
    }
}

.defaultSuccess,
.defaultWarning,
.defaultDanger,
.deleteOrder,
.marginCall,
.tradeDisabled {
    .successIcon {
        background-image: url("/images/SuccessFeedback.svg");
    }

    .dangerIcon {
        background-image: url("/images/notice.svg");
    }

    .warningIcon {
        background-image: url("/images/Wrong.svg");
    }

    .title {
        margin: 0 auto 16px;
        max-width: 360px;
        color: var(--system-feedback-mbl-title-bg);
    }
}

.defaultWarning {
    .title {
        margin: 0 auto;
    }
}

.marginCall {
    .title {
        margin-bottom: 0;
    }

    .closeIcon {
        position: absolute;
        right: 12px;
        top: 12px;
        background: var(--date-range-mbl-menu-close-icon-bg);
        height: 26px;
        width: 26px;
        border-radius: 13px;
        display: flex;

        &::before {
            margin: auto;
            height: 25px;
            width: 24px;
            content: var(--date-range-mbl-menu-close-icon-url);
        }
    }

    .description {
        @media (min-width: 768px) {
            max-width: 100%;
            margin-bottom: 48px;
        }
    }
}

.attentionText {
    color: #f25555;
}