.paymentSystems {
    margin: 45px auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 8px;

    .visaIcon,
    .mastercardIcon,
    .bankIcon {
        width: 76px; /* icons are exported with shadows, so sizes and margins are adjusted */
        height: 56px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        background-image: var(--app-modal-bank-icons-bank-icon-url);
    }

    .visaIcon {
        background-image: var(--app-modal-bank-icons-visa-icon-url);
    }

    .mastercardIcon {
        background-image: var(--app-modal-bank-icons-mastercard-icon-url);
    }

    .bankIcon {
        background-image: var(--app-modal-bank-icons-bank-icon-url);
    }
}
