.container {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: calc(100% - 16px);
    height: 62px;
    color: var(--toast-alert-color);
    bottom: calc(var(--footer-mbl-height) - 8px);
    background-color: transparent;
    align-items: center;
    margin-left: 8px;
    z-index: 1600;

    &.singleAssetView {
        top: 58px;
    }

    &.singleAssetView_market_closed {
        top: 116px
    }

    .message {
        display: flex;
        justify-content: left;
        padding-left: 16px;
        gap: 13px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        align-items: center;
        position: absolute;
        border-radius: 5px;
        width: 100%;
        height: 48px;
        background-color: var(--toast-alert-top-layer-bg);

        .fav_added_icon,
        .fav_removed_icon {
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            background-image: var(--toast-alert-icon-favorite-added-url);
        }

        .fav_removed_icon {
            background-image: var(--toast-alert-icon-favorite-removed-url);
        }
    }
}