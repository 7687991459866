.container {
    position: relative;
    user-select: none;

    &.fullWidth {
        width: 100%;
    }

    input {
        width: 100%;
    }
}

.input {
    height: 24px;
    width: 100%;
    font-size: var(--desk-font-size-1);
    font-weight: 700;
    line-height: 130%;
    padding: 4px 40px 4px 8px;
    background: transparent;
    outline: none;
    border: 1px solid var(--number-input-border);
    border-radius: 2px;
    color: var(--number-input-color);

    &:focus {
        background: var(--number-input-focus-bg);
    }

    .disabled & {
        background: #454853;
        color: var(--number-input-disabled-color);
        border-color: var(--number-input-disabled-border);
    }
}
