.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: var(--app-modal-close-trade-volume-bg);
    border-radius: 5px;
    padding: 16px 12px;
    margin-top: 16px;

    .title {
        font-weight: 500;
        font-size: var(--mbl-font-size-lg);
        line-height: 24px;
    }

    .error {
        position: absolute;
        bottom: -24px;
        left: 0;
        font-weight: 500;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        color: #F25555;
    }
}
