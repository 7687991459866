.container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;

    .bullet_wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        box-sizing: border-box;
        gap: 8px;

        .bullet {
            width: 12px;
            height: 12px;
            border-radius: 50px;
            background: var(--stepper-bullet-bg);

            &.active {
                background: var(--stepper-bullet-active-bg);
            }

            &.clickable {
                cursor: pointer;
            }
        }
    }

    .controls_wrapper {
        display: flex;
        position: absolute;
        right: 13px;
        bottom: 0;
        gap: 8px;

        .control {
            width: 30px;
            height: 30px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: var(--stepper-control-bg);

            .arrowIcon {
                color: var(--stepper-arrow-bg);
                width: 17px;
                height: 17px;
            }

            &:hover {
                background: var(--intro-first-step-button-hover-bg) !important;
            }

            &.disabled {
                background: var(--stepper-control-arrow-disabled-bg);
                cursor: default;
                pointer-events: none;
            }
        }
    }
}