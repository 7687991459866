.timeFrames {
    display: flex;
    gap: 2px;
    margin-bottom: 10px;

    .item {
        width: 36px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #89909c;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;

        &:hover {
            background-color: #89909c;
            color: #fff;
            cursor: pointer;
        }

        &.selected {
            background-color: #89909c;
            color: #fff;
            height: 28px;

            &:hover {
                cursor: default;
            }
        }
    }
}
