.timePicker {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 222px;
    height: 123px;
    padding: 18px 6px 6px;
    background: var(--date-picker-time-bg);
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--date-picker-time-container-border);

    .time_wrp {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 8px;
        margin-bottom: 16px;

        .time_control {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            gap: 12px;
            color: var(--date-picker-time-color);
            font-family: var(--main-font-family);
            font-size: var(--desk-font-size-1-4);
            font-weight: 600;
            line-height: 130%;

            .time_container {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid var(--date-picker-time-container-border);
                width: 30px;
                height: 25px;
                border-radius: 3px;

                .timeInput {
                    width: 100%;
                    border: none;
                    text-align: center;
                    outline: none;

                    color: var(--date-picker-time-color);
                    font-family: var(--main-font-family);
                    font-size: var(--desk-font-size-1-4);
                    font-weight: 600;
                }
            }

            .btn {
                width: 8px;
                height: 4px;
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }

            .up {
                width: 100%;
                background-image: url("/images/arrow_up.svg");
            }

            .down {
                width: 100%;
                background-image: url("/images/arrow_up.svg");
                -ms-transform: rotate(180deg); /* IE 9 */
                transform: rotate(180deg);
            }
        }

        .dots {
            background-image: url("/images/timepicker_colon.svg");
            width: 10px;
            height: 30px;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    .btns_container {
        display: flex;
        font-size: 12px;
        font-family: var(--main-font-family);
        font-weight: 600;
        line-height: 130%;
        gap: 8px;

        .action_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 101px;
            height: 26px;
            border-radius: 4px;
            cursor: pointer;

            &.disabled {
                background-color: var(--button-disabled-bg);
                color: var(--button-disabled-color);
                cursor: default;

                &:hover {
                    background-color: var(--button-disabled-bg);
                    color: var(--button-disabled-color);
                }
            }
        }

        .cancel_btn {
            border: 2px solid var(--date-picker-time-cancel-action-btn-border);
            color: var(--date-picker-time-cancel-action-btn-color);

            &:hover {
                border: 2px solid transparent;
                background: var(--date-picker-time-cancel-action-btn-hover-bg);
                color: var(--date-picker-time-cancel-action-btn-hover-color);
            }
        }

        .set_btn {
            background: var(--date-picker-time-set-action-btn-bg);
            color: var(--date-picker-time-set-action-btn-color);

            &:hover {
                background: var(--date-picker-time-set-action-btn-hover-bg);
                color: var(--date-picker-time-set-action-btn-hover-color);
            }
        }
    }
}

.offsetActive {
    bottom: 10px;
}