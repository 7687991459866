.container {
    @media screen and (max-width: 479px) {
        /* start of phone styles */
        display: none;
    }

    display: flex;

    height: 100%;
    max-height: 100%;
    flex: 1;
}

.collapsed {
    height: calc(100vh - var(--trades-tables-height) - var(--footer-height));
}

.footerExpandedFullScreen {
    height: 0;
}

.main_content_wrp {
    display: flex;
}
