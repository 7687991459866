.footer_mbl {
    position: fixed;
    bottom: 0;
    min-height: var(--footer-mbl-height);
    height: var(--footer-mbl-height);
    left: 0;
    right: 0;
    z-index: 5;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background: var(--footer-bg);

    border-top: 1px solid var(--footer-mbl-border);

    &.tablet {
        grid-template-columns: 1fr 0.7fr 1fr;
    }
}

.footer_mbl_btn {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    background-repeat: no-repeat;
    background-position: 50% 39%;
    padding-bottom: 14px;
    color: var(--footer-mbl-color);

    &.active {
        color: var(--footer-mbl-active-color);
    }

    >span {
        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 600;
        font-size: var(--mbl-font-size-xsm);
        line-height: 14px;
        text-align: center;
    }
}

.markets_icon {
    background-image: var(--footer-home-icon-mbl);

    &.active {
        background-image: var(--footer-home-icon-mbl-active);
    }

    &.tablet {
        justify-self: end;
    }
}

.trades_icon {
    background-image: var(--footer-update-icon-mbl);

    &.active {
        background-image: var(--footer-update-icon-mbl-active);
    }
}

.wallet_icon {
    background-image: var(--footer-wallet-icon-mbl);

    &.active {
        background-image: var(--footer-wallet-icon-mbl-active);
    }

    &.tablet {
        justify-self: start;
    }
}