.menu,
.menuCalculator {
    border-radius: 16px 16px 0 0;
    padding-top: 16px;
    height: 410px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--set-lot-size-mbl-default-bg);
    color: var(--set-lot-size-mbl-default-color);
    display: flex;
    flex-direction: column;

    &:focus-visible {
        outline: none;
    }

    .closeIcon {
        position: absolute;
        right: 16px;
        top: 16px;
        background: var(--set-lot-size-mbl-menu-close-icon-bg);
        height: 32px;
        width: 32px;
        border-radius: 20px;
        display: flex;
        background-image: var(--set-lot-size-mbl-menu-close-icon-url);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
    }

    .orderInfoList {
        padding: 40px 8px 0px;

        .orderInfoListRow {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 5px;

            .orderInfoListLabel,
            .orderInfoListValue {
                font-weight: 700;
                font-size: var(--desk-font-size-1-2);
                line-height: 16px;
                color: var(--set-lot-size-mbl-default-color);
            }
            .orderInfoListValue {
                font-weight: 500;
            }

            &:nth-child(2n) {
                background: var(--set-lot-size-mbl-order-info-row-bg);
            }
        }
    }

    .calculatorContainer {
        margin-top: 44px;
    }
}

.menuCalculator {
    height: 260px;
}
