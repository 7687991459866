.btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 700;
    padding: 3px;
    border: 2px solid;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    min-height: 62px;

    /* TODO: remove this when we remove the zoom in desktop home */
    @media (max-width: 1366px) {
        border-width: 3px;
    }

    @media (max-width: 1130px) {
        border-width: 3.5px;
    }

    &.active {
        cursor: default;
    }

    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
    }

    &.buy {
        color: var(--action-buy);
        border-color: var(--action-buy);

        &:hover,
        &.active {
            background: var(--action-buy);
            color: var(--trading-order-buy-sell-hover-color);
        }

        &.disabled {
            color: var(--trading-order-buy-sell-hover-color);
            background: var(--action-buy-disabled);
            border-color: var(--action-buy-disabled);
            pointer-events: none;
        }

        @keyframes fleshBuyBtnAnimation {
            0% {
                background-color: var(--action-buy);
                color: var(--action-buy);
            }

            100% {
                background-color: transparent;
                color: var(--action-buy);
            }
        }

        &.flashButton {
            animation-name: fleshBuyBtnAnimation;
            animation-duration: 500ms;
        }

        &.touched {
            background-color: var(--action-buy-pressed);
            border-color: var(--action-buy-pressed);
        }
    }

    &.sell {
        color: var(--action-sell);
        border-color: var(--action-sell);

        &:hover,
        &.active {
            background: var(--action-sell);
            color: var(--trading-order-buy-sell-hover-color);
        }

        &.disabled {
            color: var(--trading-order-buy-sell-hover-color);
            background: var(--action-sell-disabled);
            border-color: var(--action-sell-disabled);
            pointer-events: none;
        }

        &.touched {
            background-color: var(--action-sell-pressed);
            border-color: var(--action-sell-pressed);
        }

        @keyframes flashSellBtnAnimation {
            0% {
                background-color: var(--action-sell);
                color: var(--action-sell);
            }

            100% {
                background-color: transparent;
                color: var(--action-sell);
            }
        }

        &.flashButton {
            animation-name: flashSellBtnAnimation;
            animation-duration: 500ms;
        }
    }

    &.mobile {
        &.buy {
            &:before {
                border-color: transparent var(--trading-order-buy-sell-hover-color) transparent transparent;
                border-width: 0 11px 11px 0;
                //border-color: transparent var(--action-buy) transparent transparent;
                right: 0.5rem;
                top: 0.5rem;
            }

            // &:hover,
            // &.active {
            //     &:before {
            //     }
            // }
        }

        &.sell {
            &:before {
                border-color: transparent transparent transparent var(--trading-order-buy-sell-hover-color);
                border-width: 11px 0 0 11px;
                //border-color: transparent transparent transparent var(--action-sell);
                left: 0.5rem;
                bottom: 0.5rem;
            }

            // &:hover,
            // &.active {
            //     &:before {
            //     }
            // }
        }
    }

    &.desktop {
        &.buy {
            &:before {
                border-width: 0 11px 11px 0;
                border-color: transparent var(--action-buy) transparent transparent;
                right: 0.5rem;
                top: 0.5rem;
            }

            &:hover,
            &.active {
                &:before {
                    border-color: transparent var(--trading-order-buy-sell-hover-color) transparent transparent;
                }
            }
        }

        &.sell {
            &:before {
                border-width: 11px 0 0 11px;
                border-color: transparent transparent transparent var(--action-sell);
                left: 0.5rem;
                bottom: 0.5rem;
            }

            &:hover,
            &.active {
                &:before {
                    border-color: transparent transparent transparent var(--trading-order-buy-sell-hover-color);
                }
            }
        }
    }
}

.label {
    font-size: var(--desk-font-size-1-8);
    line-height: 22px;

    @media only screen and (max-width: 1400px) {
        font-size: var(--desk-font-size-1-5);
    }
}

.value {
    font-size: var(--desk-font-size-2-8);
    line-height: 34px;

    @media only screen and (max-width: 1400px) {
        font-size: var(--desk-font-size-2-4);
    }

    sub {
        font-size: var(--desk-font-size-2);
        vertical-align: baseline;
    }
}

.longValue {
    @media only screen and (max-width: 1600px) and (min-width: 1366px) {
        font-size: var(--desk-font-size-2-3);
    }
}

.longValue_mobile {
    @media (max-width: 410px) {
        font-size: var(--desk-font-size-2) !important;
    }
}