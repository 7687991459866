.container {
    display: grid;
    grid-template-columns: 24px 1fr;
    column-gap: 12px;
    align-items: center;
}

.directionIconContainer {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .directionIcon {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/images/graph_down.svg");
        &.dark {
            border-radius: 4px;
            background-color: rgba(244, 246, 251, 1);
        }
        &.light {
            border-radius: 4px;
            background-color: rgba(76, 81, 94, 1);
        }
    }

    &.buy {
        background-color: #16bb6f;
        .directionIcon {
            transform: scale(1, -1);
        }
    }

    &.sell {
        background-color: #f25555;
    }
}

.headerMainInfo {
    &__symbol {
        font-weight: 500;
        font-size: var(--desk-font-size-1-2);
        line-height: 16px;
        color: var(--trades-section-list-item-header-mbl-color);
    }

    &__direction {
        font-weight: 500;
        font-size: var(--desk-font-size-1);
        line-height: 14px;
        text-transform: uppercase;
        color: var(--trades-section-list-item-header-mbl-color);
    }
}
