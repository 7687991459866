.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    font-size: 18px;

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .charts {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .table {
        width: 100%;
        overflow: auto;
    }
}
