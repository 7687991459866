@import "/styles/variables.scss";

.container {
    background-color: var(--bg);
    color: var(--color);
    height: calc((var(--vh, 1vh) * 100) - var(--footer-height));
    overflow-y: hidden;
    border-top: 2px solid var(--layout-separator-color);
}

.containerTablet {
    background-color: var(--bg);
    color: var(--color);
    height: calc((var(--vh, 1vh) * 100) - var(--footer-height));
}

.containerMobile {
    background-color: var(--bg);
    color: var(--color);
    height: calc((var(--vh, 1vh) * 100) - var(--footer-height));
    touch-action: none;
    overscroll-behavior-y: contain;
}
