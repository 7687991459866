.container {
    display: flex;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background: var(--loader-bg);

    flex-direction: column;

    @media (min-width: 600px) {
        justify-content: center;
    }
}

.loaderIcon {
    width: 86px;
    height: 102px;
    background-size: 86px 102px;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-bottom: 100px;

    &.mobile {
        margin-top: 152px;
        margin-bottom: 248px;
    }

    &.tablet {
        margin-top: 72px;
    }
}