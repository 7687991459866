.container {
    .typeView {
        font-weight: 500;
        font-size: var(--mbl-font-size-md);
        line-height: 20px;
        color: var(--date-range-mbl-default-color);
        height: 40px;
        border: 1px solid var(--date-range-mbl-view-type-border);
        border-radius: 5px;
        padding: 8px 16px;
    }

    .rangeView {
        border-radius: 16px 16px 0 0;
        padding: 6px 38px;
        height: 32px;
        background: var(--date-range-mbl-view-date-bg);
        border: 1px solid var(--date-range-mbl-view-date-border);
        border-radius: 5px;
        margin-top: 8px;
        font-weight: 500;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        color: var(--date-range-mbl-default-color);
        position: relative;

        .calendarIcon {
            position: absolute;
            left: 14px;
            top: 6px;
            display: inline-block;
            width: 16px;
            height: 16px;
            content: var(--date-range-mbl-calendar-icon-url);
        }

        .datesRange {
            display: flex;
            gap: 5px;
        }
    }
}

.menu {
    border-radius: 16px 16px 0 0;
    height: 424px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--date-range-mbl-default-bg);
    color: var(--date-range-mbl-default-color);
    box-shadow: var(--app-modal-shadow);

    &:focus-visible {
        outline: none;
    }
}

.menu_tablet {
    width: 375px;
    left: calc(50% - 375px / 2);
    bottom: 25%;
}

.rangeSelectContainer {
    position: relative;
    margin-top: 16px;

    .closeIcon {
        position: absolute;
        right: 16px;
        top: 0;
        background: var(--date-range-mbl-menu-close-icon-bg);
        height: 26px;
        width: 26px;
        border-radius: 13px;
        display: flex;

        &::before {
            margin: auto;
            height: 24px;
            width: 24px;
            content: var(--date-range-mbl-menu-close-icon-url);
        }
    }

    &__header {
        font-weight: 600;
        font-size: var(--mbl-font-size-xl2);
        line-height: 32px;
        color: var(--date-range-mbl-default-color);
        padding: 0 16px 16px;
    }

    &__typeItem {
        font-weight: 500;
        font-size: var(--mbl-font-size-lg);
        line-height: 24px;
        color: var(--date-range-mbl-default-color);
        padding: 12px 16px;
        border-top: 1px solid var(--date-range-mbl-menu-type-item-border);

        &.active {
            color: #007bff;
        }
    }
}

.selectCustomRange {
    height: 100%;

    .btnContainer {
        padding: 0 16px 8px;
        background: var(--date-range-mbl-default-bg);
        display: flex;
        grid-template-columns: 1fr 1fr;
        column-gap: 11px;
        margin-top: 47px;

        .btn {
            height: 40px;
            width: 100%;
            border-radius: 8px;
            background-color: transparent;
            font-weight: 600;
            font-size: var(--mbl-font-size-xs);
            line-height: 16px;
            text-align: center;
            color: #ffffff;
        }

        .btnTransparent {
            border: 1px solid var(--app-modal-close-trade-btn-transparent-border-color);
            color: var(--app-modal-close-trade-btn-transparent-color);

            &.touched {
                border: 1px solid transparent;
                background-color: var(--calendar-btn-cancel-touched-bg);
                color: var(--calendar-btn-cancel-touched-color);
            }
        }

        .btnApply {
            background: var(--trades-section-selected-trade-btn-apply-mbl-bg);
            color: var(--trades-section-selected-trade-btn-apply-mbl-color);
            border: transparent;

            &.touched {
                background-color: var(--calendar-btn-set-touched-bg);
                color: var(--calendar-btn-set-touched-color);
            }
        }
    }
}