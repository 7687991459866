.container {
    display: flex;
    align-items: center;
    gap: 8px;

    object {
        position: relative;
        width: 24px;
        height: 24px;
        z-index: 2;
        display: inline-block;
        vertical-align: top;

        &:last-child {
            z-index: 1;
            margin-left: -6px;
        }
    }

    .symbolMainInfoName,
    .symbolMainInfoName__light {
        color: var(--asset-list-symbol-main-info-name-color);
        font-weight: 600;
        font-size: var(--desk-font-size-2);
        line-height: 24px;
    }
}
