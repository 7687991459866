.container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    min-width: 247px;
    overflow-y: hidden;
    border-right: 2px solid var(--layout-separator-color);

    &.isModalOpen {
        overflow: hidden;
        height: 100%;
    }

    @-moz-document url-prefix() {
        @media (max-width: 1366px) {
            margin-right: -60px;
        }

        @media (max-width: 1260px) {
            margin-right: -80px;
        }

        @media (max-width: 1130px) {
            margin-right: -86px;
        }

        @media (max-width: 920px) {
            margin-right: -100px;
        }

        @media (max-width: 800px) {
            margin-right: -95px;
        }
    }
    @media screen and (max-width: 1366px) {
        :global(body.not-chrome) & {
            transform: scale(0.8);
            transform-origin: left top;
            height: calc(100% + 1px);
        }
        :global(body.chrome) & {
            zoom: 80%;
        }
    }

    @media screen and (max-width: 1366px) {
        :global(body.not-chrome) & {
            transform: scale(0.8);
            transform-origin: top left;
            height: auto;
        }

        :global(body.chrome) & {
            zoom: 80%;
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1366px) {
            height: calc(100% + 265px);
        }
    }

    @media (max-width: 1260px) {
        :global(body.not-chrome) & {
            transform: scale(0.7);
            transform-origin: top left;
            height: auto;
        }

        :global(body.chrome) & {
            zoom: 70%;
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1260px) {
            height: calc(100% + 358px);
        }
    }

    @media (max-width: 1130px) {
        :global(body.not-chrome) & {
            transform: scale(0.65);
            transform-origin: top left;
            height: auto;
        }

        :global(body.chrome) & {
            zoom: 65%;
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1130px) {
            height: calc(100% + 447px);
        }
    }

    @media screen and (max-width: 920px) {
        :global(body.not-chrome) & {
            transform: scale(0.6);
            transform-origin: top left;
            height: auto;
        }

        :global(body.chrome) & {
            zoom: 60%;
        }
    }
}

.overlayed {
    pointer-events: none;
    z-index: 1305;
    border: none;
    background-color: var(--trade-order-section-overlayed-bg);
}

.tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 100%;

    text-align: center;

    .tab {
        max-width: 50%;
        white-space: normal !important;
        word-break: normal !important;
        flex-grow: 1;
        background: var(--trading-order-tabs-bg);
        color: var(--trading-order-tabs-color);
        font-size: var(--desk-font-size-1-6);
        line-height: 1.2;
        font-weight: 600;
        margin: auto;
        text-align: center;
        padding: 8px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 400px;

        @media screen and (max-width: 1366px) {
            padding: var(--desk-font-size-1-1) var(--desk-font-size-1-8);
        }

        @media screen and (max-width: 1028px) {
            padding: var(--desk-font-size-1-1) var(--desk-font-size-1-7);
        }

        &:hover {
            color: var(--trading-order-tabs-hover-color);
            max-height: 400px;
        }

        &.tabActive {
            background: var(--trading-order-tabs-active-bg);
            color: var(--trading-order-tabs-active-color);
            max-height: 400px;
        }
    }
}

.content {
    padding: 12px;
    box-sizing: border-box;
    flex-grow: 1;
}

.orderFormHeader {
    width: 100%;
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.assetTopInfo {
    width: 100%;
    display: flex;
    align-items: center;
}

.assetIcon {
    margin-right: 6px;

    img {
        width: 24px;
        vertical-align: top;
        position: relative;
        z-index: 2;

        &:last-child {
            margin-left: -6px;
            z-index: 1;
        }
    }

    &.singleAssetIcon {
        height: 24px;
        width: 24px;
    }
}

.assetName {
    font-size: var(--desk-font-size-2-2);
    line-height: 1;
    letter-spacing: -1px;
    color: var(--trading-order-top-info-asset-name-color);
}

.closeIcon {
    height: 23px !important;
    width: 23px !important;

    &:hover {
        cursor: pointer;
        fill: #9098ad;
    }
}

.orderInfoList {
    margin: 0 0 8px 0;
    color: #89909c;
    font-family: var(--main-font-family);
    font-weight: 600;
    line-height: 16.9px;
}

.disabled {
    opacity: 0.4;
}

.orderInfoListRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: var(--trading-order-info-list-row-color);
    font-size: 12px;
}

.orderInfoListLabel_light,
.orderInfoListLabel_dark {
    text-align: left;
}

.orderInfoListValue_light,
.orderInfoListValue_dark {
    text-align: right;
}

.tradeButtonWrap {
    padding-right: 4px;
}

.tradeButton_fixed {
    bottom: 12px;
    z-index: 9;
    width: 351.8px;
    position: fixed;
    bottom: calc(var(--trades-tables-height) + var(--footer-height));
    padding-bottom: 0.5rem;

    @media (max-width: 1360px) {
        bottom: 443px;
    }

    @media (max-width: 1260px) {
        bottom: 505px;
    }

    @media (max-width: 1170px) {
        bottom: 503px;
    }

    @media (max-width: 1130px) {
        bottom: 543px;
    }
}

.tradeButton {
    height: 50px;

    &:global(.MuiButtonBase-root) {
        font-family: var(--main-font-family);
        border-radius: 8px;
        font-size: var(--desk-font-size-2-4);
        font-weight: 700;
        letter-spacing: normal;
        line-height: 130%;
        text-transform: none;
        padding: 0.6rem 1rem;
        box-shadow: none !important;
        margin-top: 1rem;
    }
}

.tradeButton_dark {
    &:global(.MuiButtonBase-root) {
        background-color: var(--trading-order-submit-bg_dark);
        color: var(--trading-order-submit-color_dark);

        &:hover {
            background-color: var(--trading-order-submit-hover-bg_dark);
        }

        &[disabled] {
            background-color: var(--trading-order-submit-disabled-bg_dark);
            color: var(--trading-order-submit-disabled-color_dark);
            opacity: 1;
        }
    }
}

.tradeButton_light {
    &:global(.MuiButtonBase-root) {
        background-color: var(--trading-order-submit-bg-light);
        color: var(--trading-order-submit-color-light);

        &:hover {
            background-color: var(--trading-order-submit-hover-bg_light);
        }

        &[disabled] {
            background-color: var(--trading-order-submit-disabled-bg_light);
            color: var(--trading-order-submit-disabled-color_light);
            opacity: 1;
        }
    }
}

.container_dark {
    background-color: #1d1f24;
}

.container_light {
    background-color: #fff;
}

.buySellButtons {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 8px 0 0 0;
    gap: 6px;

    .buy:hover {
        background-color: var(--action-buy) !important;
        color: var(--trading-order-buy-sell-hover-color) !important;
    }

    .sell:hover {
        background-color: var(--action-sell) !important;
        color: var(--trading-order-buy-sell-hover-color) !important;
    }

    .pipsValue,
    .pipsValue__light {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 0 0;
        padding: 10px 20px;
        height: 20px;
        color: #9098ad;
        background-color: #1c1f25;
        line-height: 15px;
        font-size: var(--desk-font-size-09);
        letter-spacing: 1px;
    }

    .pipsValue__light {
        background-color: #ffffff;
    }
}

.collapsed {
}

.formsWrapper {
    position: relative;
    overflow-x: hidden;
}

.backbroundDynamicButton {
    background-color: var(--bg);
    width: 100%;
    flex: 1;
    display: flex;
}
