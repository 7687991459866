.menu,
.menu__light {
    padding-top: 16px 15px 0 15px;
    height: calc(100 * var(--vh));
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--set-lot-size-mbl-default-bg);
    color: var(--set-lot-size-mbl-default-color);
    display: flex;
    flex-direction: column;

    &:focus-visible {
        outline: none;
    }

    .menu__title {
        font-weight: 600;
        font-size: var(--mbl-font-size-xl);
        line-height: 24px;
        padding-top: 12px;
        padding-left: 16px;
        padding-bottom: 36px;
    }

    .menu__item,
    .menu__item__light {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 16px 16px 16px;
        height: 64px;
        background-color: #343841;
        border-radius: 5px;
        padding: 8px 0 8px 18px;

        .menu__itemTitle {
            font-weight: 600;
            font-size: var(--mbl-font-size-lg);
            line-height: 24px;
        }

        .menu__itemDesc,
        .menu__itemDesc__light {
            font-weight: 500;
            font-size: var(--mbl-font-size-xs);
            line-height: 16px;
            color: #9098ad;
        }

        .menu__itemDesc__light {
            color: #4c515e;
        }
    }

    .menu__item__light {
        background-color: #ffffff;
    }

    .closeIcon {
        position: absolute;
        right: 16px;
        top: 16px;
        background: var(--set-lot-size-mbl-menu-close-icon-bg);
        height: 26px;
        width: 26px;
        border-radius: 13px;
        display: flex;

        &::before {
            margin: auto;
            height: 25px;
            width: 25px;
            content: var(--set-lot-size-mbl-menu-close-icon-url);
        }
    }
}

.menu__light {
    background: #f4f6fb;
    color: #000000;
}
