.container {
    border-radius: 32px;
    position: relative;
    background-color: var(--asset-list-search-bg);
    border: 1.5px solid var(--asset-list-mbl-search-border);
    margin-right: 1rem;
    width: 100%;
    height: 32px;

    &.hasValue {
        box-shadow: 0 0 0 5px var(--asset-list-mbl-search-outline);
    }

    .input {
        color: inherit;
        width: 100%;
        border: none;

        :global(.MuiInputBase-input) {
            box-sizing: border-box;
            padding: 0 2rem 0 1.5rem;
            height: 32px;
            width: 100%;
            font-size: var(--desk-font-size-1-4);
            font-family: var(--main-font-family);
            color: var(--asset-list-search-text-color);
            outline: none!important;
            border: none;
            box-shadow: none;
            margin-top: -1.5px;

            &::placeholder {
                color: var(--asset-list-search-color);
                opacity: 1;
            }

            &:focus {
                &::placeholder {
                    color: var(--asset-list-search-hover-color);
                }
            }
        }
    }

    .iconWrapper {
        padding: 0 6px 0 0;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border-radius: 0 32px 32px 0;
        display: grid;
        place-items: center;

        .closeIcon {
            background: var(--asset-list-order-item-market-closed-border);
            height: 20px;
            width: 20px;
            padding: 0;
        }

        .search_icon,
        .search_icon_dimmed {
            width: 24px;
            height: 24px;
            background: transparent var(--asset-list-search-icon-url) no-repeat center;
        }

        .search_icon_dimmed {
            background: transparent var(--asset-list-search-grey-icon-url) no-repeat center;
        }
    }

    &.hasValue {
        .iconWrapper {
            .search_icon,
            .search_icon_dimmed {
                width: 32px;
                height: 32px;
            }
        }
    }
}
