@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.2;
    }
}

@keyframes fadeOut_light {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
}

@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
@keyframes wave-squares {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.popup_wrapper {
    display: flex;
    flex-direction: column;
    width: 378px;
    height: 600px;
    position: absolute;
    background-color: #ffffff;
    z-index: 99;
    bottom: 72px;
    right: 164px;
    border-radius: 16px;
    box-sizing: border-box;

    overflow: hidden;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 24px 0px rgba(28, 31, 37, 0.2);

    &.fadeOut {
        animation-name: fadeOut;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    &.fadeOut_light {
        animation-name: fadeOut_light;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
    }

    &:hover {
        opacity: 1;
        animation-name: none;
    }
    @media (max-width: 1366px) {
        bottom: 58px;
        right: 88px;
    }
    @media (max-height: 720px) {
        height: 500px;
    }

    .popup_header {
        display: flex;
        width: 354px;
        height: 58px;
        margin: 12px 12px 10px 12px;
        justify-content: end;
        align-items: end;
        flex-direction: column;
        grid-gap: 10px;

        .close_icon {
            height: 10px;
            display: flex;
            align-items: center;
            position: relative;
            color: #000;
            width: 10px;
            cursor: pointer;

            &::before {
                display: inline-block;
                font-size: var(--desk-font-size-1-6);
                content: "\00d7";
                margin-top: -2px;
            }
        }

        .close_icon {
            height: 10px;
            display: flex;
            align-items: center;
            position: relative;
            color: #000;
            width: 10px;
            cursor: pointer;

            &::before {
                display: inline-block;
                font-size: var(--desk-font-size-1-6);
                content: "\00d7";
                margin-top: -2px;
            }
        }
    }

    .dummyBackground {
        width: 100%;
        height: 596px;
        background-size: 100%;
        background-position: 0;
        background-repeat: no-repeat;
        background-image: url("/images/alerts-bg.svg");
    }
}

.symbolMainInfoImages {
    margin-right: 6px;
    white-space: nowrap;

    object {
        /* object is used in order to avoid 404 images html and provide default image fallback  */
        position: relative;
        width: 24px;
        height: 24px;
        z-index: 2;
        display: inline-block;
        vertical-align: top;

        &:last-child {
            z-index: 1;
            margin-left: -6px;
        }
    }
}

.symbolMainInfoSingleImage {
    object {
        &:last-child {
            margin-left: 0;
        }
    }
}
.symbolImagesCell {
    display: flex;
    align-items: flex-start;
    width: 54px;

    @media screen and (max-width: 479px) {
        min-width: unset;
    }
}

.symbolMainInfoImages {
    margin-right: 6px;
    white-space: nowrap;

    &.symbolMainInfoImagesSingleImage {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 479px) {
        margin-right: 0;
    }

    object {
        /* object is used in order to avoid 404 images html and provide default image fallback  */
        position: relative;
        width: 24px;
        height: 24px;
        z-index: 2;
        display: inline-block;
        vertical-align: top;

        &:last-child {
            z-index: 1;
            margin-left: -3px;
        }
    }
}

.messageItem_container {
    display: grid;
    grid-template-columns: 54px 272px;
    color: #343841;
    width: 354px;
    padding: 10px 12px 10px 10px;
    border-radius: 8px;
    border: 1px solid #89909c;
    margin: 0 12px 10px 12px;
    box-sizing: border-box;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
        background-color: #15171c;
        color: #ffffff;
        .toggle_btn {
            &.collapsed {
                background-image: url("/images/arrow_up_white.svg");
            }
            &.expanded {
                background-image: url("/images/arrow_up_white.svg");
            }
        }
    }

    .container_collapsed {
        height: 96px;
    }
    .container__expanded {
        height: auto;
    }

    .column {
        display: flex;
        justify-content: center;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        .currency {
            font-family: "Inter";
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
        }
        .date {
            font-family: "Inter";
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            color: #89909c;
        }
    }
    .message {
        display: -webkit-box;
        width: 272px;
        text-align: left;

        font-family: "Inter";
        font-size: 13px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;

        .dropped {
            color: rgba(242, 85, 85, 1);
            padding-left: 4px;
        }
        .raised {
            color: rgba(22, 187, 111, 1);
            padding-left: 4px;
        }
    }

    .toggle_btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 100%;

        &.collapsed {
            background-image: url("/images/arrow_up_black.svg");
            transform: rotate(180deg);
            background-position: 0 4px;
        }
        &.expanded {
            background-image: url("/images/arrow_up_black.svg");
            background-position: 0 -4px;
        }
    }
}

.scrollBar_override {
    :global {
        .ps .ps__thumb-y {
            width: 5px;
            background: rgba(187, 201, 213, 1) !important;
            opacity: 1 !important;
        }
        .ps .ps__thumb-y:hover,
        .ps .ps__thumb-y:focus,
        .ps .ps__thumb-y.ps--clicking {
            width: 5px;
            opacity: 1 !important;
        }
        .ps .ps__rail-y {
            opacity: 1 !important;
        }
        .ps .ps__rail-x:hover,
        .ps .ps__rail-y:hover,
        .ps .ps__rail-x:focus,
        .ps .ps__rail-y:focus,
        .ps .ps__rail-x.ps--clicking,
        .ps .ps__rail-y.ps--clicking {
            width: 5px;
            opacity: 1 !important;
        }
    }
}

.no_results_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .rectangle {
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
        border-radius: 12px;

        background-color: rgba(52, 56, 65, 1);
        background-image: url("/images/no-results-icon-white.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px;
    }
    .rectangle_border {
        width: 56px;
        height: 56px;
        margin-bottom: 24px;
        border-radius: 12px;
        background-color: white;
        border: 2px solid rgba(52, 56, 65, 1);
        background-image: url("/images/no-results-icon-black.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 28px;
    }
    .text {
        font-family: var(--main-font-family);
        font-size: 25px;
        font-weight: 700;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: rgba(52, 56, 65, 1);
    }
}
.lockedWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 378px;
    height: 600px;
    position: absolute;
    z-index: 101;
    border-radius: 16px;
    color: rgba(0, 0, 0, 1);

    .lockedContent {
        display: flex;
        flex-direction: column;
        width: 240px;
        align-items: center;
        box-sizing: border-box;

        .lockIcon {
            width: 32px;
            height: 32px;
            background-image: url("/images/lock_gray.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto;
            margin-bottom: 24px;
        }

        .lockTitle {
            font-size: 24px;
            font-weight: 700;
            line-height: 31.2px;
            text-align: center;
            margin-bottom: 8px;
        }

        .lockSubtitle {
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
        }

        .lockButtonsContainer {
            display: flex;
            flex-direction: column;
            margin-top: 24px;

            .lockButton {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 240px;
                height: 42px;
                font-size: 14px;
                font-weight: 700;
                line-height: 18.2px;
                text-align: center;
                border-radius: 8px;
                cursor: pointer;

                &.signup {
                    color: rgba(255, 255, 255, 1);
                    background-color: #000;
                    border: none;
                    margin-bottom: 8px;
                    transition: all 0.2s;

                    &:hover {
                        background-color: #464a53;
                        border: none;
                    }
                }

                &.login {
                    color: #000;
                    border: 2px solid rgba(0, 0, 0, 1);
                    background-color: #fff;
                    transition: all 0.2s;

                    &:hover {
                        background-color: #e8e9ee;
                        border-color: #89909c;
                    }
                }
            }
        }
    }
}

.skeletonContainer {
    display: grid;
    grid-template-columns: 44px 272px;
    width: 354px;
    height: 79px;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid rgba(245, 245, 245, 1);
    border-radius: 8px;

    .skeletonRow {
        display: grid;
        grid-template-columns: 208px 52px;
        column-gap: 12px;
        row-gap: 4px;
    }

    .skeletonRound {
        width: 24px;
        height: 24px;
        border-radius: 50px;

        background: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(8%, rgba(130, 130, 130, 0.2)),
            color-stop(18%, rgba(130, 130, 130, 0.3)),
            color-stop(33%, rgba(130, 130, 130, 0.2))
        );
        background: linear-gradient(
            to right,
            rgba(245, 245, 245, 1) 8%,
            rgba(130, 130, 130, 0.3) 18%,
            rgba(130, 130, 130, 0.2) 33%
        );
        background-size: 800px 100px;
        animation: wave-squares 3s infinite ease-out;
    }

    .skeletonStripe {
        background: -webkit-gradient(
            linear,
            left top,
            right top,
            color-stop(8%, rgba(130, 130, 130, 0.2)),
            color-stop(18%, rgba(130, 130, 130, 0.3)),
            color-stop(33%, rgba(130, 130, 130, 0.2))
        );
        background: linear-gradient(
            to right,
            rgba(245, 245, 245, 1) 8%,
            rgba(130, 130, 130, 0.3) 18%,
            rgba(130, 130, 130, 0.2) 33%
        );
        background-size: 800px 100px;
        animation: wave-squares 3s infinite ease-out;

        &.short {
            width: 52px;
            height: 21px;
        }
        &.middle {
            width: 208px;
            height: 21px;
        }
        &.long {
            width: 272px;
            height: 34px;
        }
    }
}
