.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .label {
        font-weight: 600;
        line-height: 22px;
        color: #fff;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        background: #15171c;
        padding: 12px;
        width: 100%;

        .charts {
            display: flex;
        }

        .inline {
            display: flex;
        }
    }
}
