.tabContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 4px;

    &.tablet {
        column-gap: 10px;
    }

    .tabBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 8px;
        font-weight: 500;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        background: var(--trades-section-tabs-mbl-bg);
        border-radius: 5px;
        color: var(--trades-section-tabs-mbl-color);

        &.active {
            background: var(--trades-section-tabs-mbl-active-bg);
            font-weight: 600;
            color: var(--trades-section-tabs-mbl-active-color);
        }
    }
}
