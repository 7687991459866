.container {
    width: 378px;
    height: 600px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 12px 0;
    border-radius: 16px;
    position: absolute;
    bottom: 70px;
    right: 220px;
    overflow: hidden;
    box-shadow: 0px 8px 24px 0px #1c1f2533;

    .closeIcon {
        position: absolute;
        right: 12px;
        cursor: pointer;
        z-index: 2;
    }

    .filters {
        display: flex;
        justify-content: space-between;
        align-items: end;
        height: 78px;
        width: 100%;
        padding: 22px 12px 8px;
        position: relative;

        .demo {
            display: flex;
            position: absolute;
            left: 50%;
            top: 10%;
            transform: translate(-50%, -50%);
            color: red;
            font-size: 14px;
        }

        .impactSelect {
            height: 28px;
            width: 120px;
            border: 1px solid #89909c;
            border-radius: 6px;
            color: #000;

            &:hover {
                opacity: 0.7;
            }
        }

        .countrySelect {
            width: 52px;
            height: 28px;
            border: 1px solid #89909c;
            border-radius: 6px;
        }
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;

        .headers {
            display: flex;
            justify-content: flex-end;
            height: 16px;
            margin-top: 10px;
            margin-bottom: 10px;

            .tab {
                font-weight: 700;
                font-size: 12px;
                width: 26%;
                border-left: 1px solid #89909c;
                display: flex;
                justify-content: center;
                align-items: center;

                &:first-child {
                    border-left: none;
                }
            }
        }

        .itemsList {
            display: flex;
            flex-direction: column;
            overflow: auto;
            height: calc(600px - 24px - 114px);

            .block {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .datetime {
                    height: 37px;
                    background-color: #e6ecf1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    font-size: 13px;
                    border-radius: 8px;
                    margin-top: 10px;
                    margin-left: 12px;
                    margin-right: 12px;
                }

                .item {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 74px;
                    border: 1px solid #89909c;
                    border-radius: 8px;
                    margin-right: 12px;
                    margin-left: 12px;
                    padding: 10px;
                    transition: background-color 250ms;

                    &:hover {
                        background-color: #15171c;
                        color: #fff;
                    }

                    .label {
                        display: flex;
                        justify-content: space-between;

                        .title {
                            font-weight: 700;
                            font-size: 14px;
                        }

                        .timestamp {
                            font-weight: 400;
                            font-size: 13px;
                            color: #89909c;
                        }
                    }

                    .metrics {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        font-size: 13px;

                        .countryFlag {
                            margin-right: 5px;
                            border-radius: 50%;
                        }

                        .value {
                            width: 26%;
                            border-left: 1px solid #89909c;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:nth-child(3) {
                                border-left: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
