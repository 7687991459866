.card {
    display: flex;
    align-items: center;
    gap: 6px;

    .logo {
        position: relative;
        top: 3px;
    }

    .info {
        display: flex;
        flex-direction: column;
        width: 95px;
        justify-content: center;
        gap: 2px;

        .name {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #e6ecf1;
        }

        .company {
            font-weight: 600;
            font-size: 8px;
            line-height: 10px;
        }

        .rating {
            display: flex;
            gap: 2px;
        }
    }
}
