.section {
    flex: 1;
    height: 100%;
    min-width: 400px;
}

.content {
    color: var(--info-text-color);
    height: 100%;

    &.tradesTableOpen {
        height: calc(100vh - 66px - var(--footer-height) - var(--trades-tables-height));
    }
}

.overlayed {
    pointer-events: none;
    z-index: 1306;
    background-color: var(--layout-bg);
}

.sectionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .narrow & {
        flex-wrap: wrap;
    }
}

.dateTime {
    position: fixed;
    right: 0;
    font-weight: 600;
    font-size: var(--desk-font-size-1-2);
    line-height: 1.2;
    color: var(--chart-time-color);
    min-width: 150px;

    @media (max-width: 1488px) {
        margin-left: 0;
    }
}

.licenseExpirationNotifier {
    font-weight: 600;
    font-size: var(--desk-font-size-1-2);
    line-height: 1.2;
    color: var(--action-sell);
}

.chartWrap {
    position: relative;
}

.chartLoader {
    position: absolute;
    top: 40px;
    left: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    background: var(--chart-loader-bg);
    z-index: 98;
    /* should not be more than trade history */
}

.loadingBox {
    position: relative;
    width: 150px;
    height: 6px;
    border-radius: 6px;
    background: var(--chart-loader-line-bg);
    border: none;
    overflow: hidden;
}

.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 6px;
    background: var(--chart-loader-line-fill-bg);
    left: -100%;
    animation: load 3s linear infinite;
}

@keyframes load {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}

.assetTopInfo {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10;
    left: 50px;
    top: 62px;
}

.assetIcon {
    position: relative;
    margin-right: 6px;

    img {
        width: 24px;
        vertical-align: top;
        position: relative;
        z-index: 2;

        &:last-child {
            margin-left: -6px;
            z-index: 1;
        }
    }

    &.singleAssetIcon {
        height: 24px;
        width: 24px;
    }

    @media (max-width: 1165px) {
        bottom: 25px;
    }
}

.assetName {
    position: relative;
    font-size: var(--desk-font-size-2);
    line-height: 1;
    letter-spacing: -1px;
    color: var(--trading-order-top-info-asset-name-color);

    @media (max-width: 1165px) {
        bottom: 25px;
    }
}

.collapsed {
    padding: 0 2rem;
}

.chart {
    width: 100%;
    min-height: calc(100vh - 175px);
}

.tabContent {
    padding: 32px 20px 24px 20px;
    height: 100%;

    &.hidden {
        display: none;
    }
}

.scrollContent {
    overflow: auto;

    &::-webkit-scrollbar-thumb {
        visibility: hidden;
    }

    &:hover {
        &::-webkit-scrollbar-thumb {
            visibility: visible;
        }
    }
}

.navigationTabs {
    display: flex;
    align-items: center;
    padding: 12px 0 0;
    gap: 8px;
    margin-left: 2rem;

    .navTab {
        padding: 0;
        background: transparent;
        overflow: visible;

        .navTabButton {
            text-transform: none;
            font-size: var(--desk-font-size-1-2);
            line-height: 1.3;
            padding: 0.3rem 2rem;
            font-family: var(--main-font-family);
            font-weight: 600;
            border-radius: 60px;
            transition: all 0s linear;
            background-color: var(--chart-nav-btn-bg);
            color: var(--chart-nav-btn-color);
            box-shadow: none;
            padding: 1.2rem 2.4rem;

            &:hover {
                color: var(--chart-nav-btn-hover-color);
            }

            &.active {
                z-index: 1;
                background-color: var(--chart-nav-btn-active-bg);
                color: var(--chart-nav-btn-active-color);
            }
        }

        .navTabOpenTrade {
            font-family: var(--main-font-family);
            font-weight: 500;
            font-size: var(--desk-font-size-1-6);
            line-height: 125%;
            background: var(--chart-nav-open-trade-btn-bg);
            color: var(--chart-nav-open-trade-btn-color);
            border: var(--chart-nav-open-trade-btn-border);
            border-radius: 8px;
            padding: 10px 16px;
            margin-right: 20px;
            box-shadow: none;
            text-transform: none;
            transition: all 0s;

            &:hover {
                background: var(--chart-nav-open-trade-btn-hover-bg);
                color: var(--chart-nav-open-trade-btn-hover-color);
            }

            img {
                width: 16px;
                margin-right: 10px;
            }
        }
    }
}

.grid_section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;

    &.singleColumn {
        @media (max-width: 1750px) {
            grid-gap: 0px;
            grid-template-columns: 1fr;
        }
    }

    .grid_item {
        flex-direction: column;
    }

    @media (max-width: 1367px) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.hideOnSmallScreen {
    @media (max-width: 1367px) {
        display: none !important;
    }
}

.showOnSmallScreen {
    @media (max-width: 1367px) {
        display: flex;
    }

    @media (min-width: 1367px) {
        display: none !important;
    }
}
