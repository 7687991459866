.container {
    position: relative;
    height: calc(100 * var(--vh));
    width: 100%;
    background-color: #15181d;

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: 100vw;
    // }
}

.mainInfoSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100 * var(--vh) - 309px);

    &.tablet {
        height: calc(100 * var(--vh) - 473px);
    }

    @media (min-width: 390px) {
        justify-content: flex-start;
    }

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(100 * var(--vh) - 309px) !important;
    // }
}

.footerInfoSection {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: var(--wallet-text-bg);
    border-radius: 16px 16px 0 0;
}

.detailsInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 40px 75px 40px;
    gap: 40px;

    @media (min-width: 768px) {
        gap: 235px;
    }
}

.column {
    display: flex;
    flex-direction: column;
    gap: 31px;
}

.formattedValue {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 40px;
}

.title {
    font-size: 12px;
    line-height: 16px;
    color: var(--wallet-text-grey-color);
    margin-bottom: 2px;
}

.value {
    font-size: 16px;
    line-height: 20px;
    color: var(--wallet-text-black-color);
    white-space: nowrap;
}

.infoIcon {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 20px;
    width: 20px;
    background: transparent var(--wallet-info-icon) no-repeat center;
    filter: invert(67%) sepia(8%) saturate(726%) hue-rotate(186deg) brightness(89%) contrast(87%);
}

.cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
    height: 222px;
    font-size: 16px;
    line-height: 20px;
    color: var(--wallet-text-black-color);
    margin-bottom: var(--footer-mbl-height);

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: 222px !important;
    //     margin: 0 auto 40px;
    // }

    &.tablet {
        height: 387px;
    }

    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 145px;
        height: 133px;
        gap: 22px;
        border: 1.5px solid var(--wallet-border);
        box-shadow: 0px 0px 0px 5px var(--wallet-border-light);
        border-radius: 16px;

        &.tablet {
            width: 250px;
            height: 230px;
        }

        &.touched {
            border: 2px solid var(--wallet-border-touched);
        }

        // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        //     width: 145px !important;
        //     height: 133px !important;
        // }
    }
}

.depositIcon {
    height: 32px;
    width: 32px;
    background: transparent var(--wallet-deposit-icon) no-repeat center;

    @media (min-width: 768px) {
        background: transparent var(--wallet-deposit-icon-tablet) no-repeat center;
        height: 56px;
        width: 56px;
    }
}

.withdrawalIcon {
    height: 32px;
    width: 32px;
    background: transparent var(--wallet-withdrawal-icon) no-repeat center;

    @media (min-width: 768px) {
        background: transparent var(--wallet-withdrawal-icon-tablet) no-repeat center;
        height: 56px;
        width: 56px;
    }
}

.btnTitle {
    text-align: center;

    &.longText {
        height: 25px;
    }
}

.closeIcon_dark,
.closeIcon_light {
    position: absolute;
    right: 11px;
    top: 14px;
    display: flex;
    height: 26px;
    width: 26px;
    border-radius: 13px;
    z-index: 9999;

    &::before {
        content: "";
        display: block;
        margin: auto;
        height: 24px;
        width: 24px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.closeIcon_dark {
    background: var(--wallet-close-icon-bg);

    &::before {
        background-image: var(--wallet-close-btn-icon);
    }
}

.closeIcon_light {
    background: var(--wallet-close-icon-bg-light);

    &::before {
        background: var(--wallet-close-btn-icon-light);
    }
}
