.container {
    height: calc((var(--vh, 1vh) * 100) - var(--footer-mbl-height));

    &.isNoOrders {
        padding-top: 56px;
    }

    &.containerClosedTrades {
        padding-top: 146px;

        &.isCustomDateRange {
            padding-top: 200px;
        }

        &.isNoOrders {
            padding-top: 112px;

            &.isCustomDateRange {
                padding-top: 152px;
            }
        }
    }

    &.containerOpenTrades {
        padding-top: 104px;
    }

    &.containerPendingOrders {
        padding-top: 104px;
    }
}

.containerScrollActive {
    padding-right: 4px !important;
}

.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    //max-height: 104px;
    z-index: 1;
    padding: 8px 15px 0px;
    background: var(--bg);

    .toolbar__searchWrapper {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 16px;

        &.withButtons {
            grid-template-columns: 1fr 8px 32px;
        }

        .btn {
            display: flex;
            width: 32px;
            height: 32px;
            border: 1px solid var(--trades-section-toolbar-btn-border);
            border-radius: 20px;

            margin-left: 8px;

            &::before {
                margin: auto;
                content: url("/images/delete.svg");
            }
        }

        .iconEmail {
            &::before {
                margin-top: 6px;
                content: url("/images/email.svg");
            }
        }

        .iconEmail_light {
            &::before {
                margin-top: 6px;
                content: url("/images/emailBlack.svg");
            }
        }

        .iconDownload {
            &::before {
                content: var(--trades-history-mbl-download-pdf-btn-icon);
            }
        }
    }
}

.dateRangeWrap {
    margin-top: 16px;
}

.tradesListContainer {
    padding: 0 5px;
    //overflow-y: auto;

    &::-webkit-scrollbar-thumb {
        height: 10px;
    }
}

.tradesListOpenTrades {
    height: calc((var(--vh, 1vh) * 100) - var(--footer-mbl-height) - 104px);
    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(100vw - var(--footer-mbl-height) - 104px) !important;
    // }
}

.tradesListClosedTrades {
    height: calc((var(--vh, 1vh) * 100) - var(--footer-mbl-height) - 160px);

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(100vw - var(--footer-mbl-height) - 160px) !important;
    // }

    &.tradesListIsDateRange {
        height: calc((var(--vh, 1vh) * 100) - var(--footer-mbl-height) - 200px);

        // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        //     height: calc(100vw - var(--footer-mbl-height) - 200px) !important;
        // }
    }
}

.tradesListPendingOrders {
    height: calc((var(--vh, 1vh) * 100) - var(--footer-mbl-height) - 104px);

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(100vw - var(--footer-mbl-height) - 104px) !important;
    // }
}

.tradesListIsNoOrders {
    height: calc((var(--vh, 1vh) * 100) - var(--footer-mbl-height) - 112px);

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(100vw - var(--footer-mbl-height) - 112px) !important;
    // }
}

.tradesListIsDateRange {
    height: calc((var(--vh, 1vh) * 100) - var(--footer-mbl-height) - 152px);

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(100vw - var(--footer-mbl-height) - 152px) !important;
    // }
}
