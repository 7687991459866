.popup_container {
    position: absolute;
    width: 155px;
    height: 46px;
    top: 0;
    left: 0;
    padding: 4px 2px;
    border-radius: 2px 0 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 1;
    z-index: 99;
    &.dark {
        background-color: rgba(230, 236, 241, 1);
        color: rgba(52, 56, 65, 1);
        font-family: Inter;
        font-size: 15px;
        font-weight: 700;
        line-height: 19.5px;
        text-align: center;
        &::before {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: rgba(230, 236, 241, 1) transparent transparent transparent;
        }
    }
    &.light {
        background-color: rgba(52, 56, 65, 1);
        color: rgba(255, 255, 255, 1);
        font-family: Inter;
        font-size: 15px;
        font-weight: 700;
        line-height: 19.5px;
        text-align: center;
        &::before {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%);
            border-width: 10px;
            border-style: solid;
            border-color: rgba(52, 56, 65, 1) transparent transparent transparent;
        }
    }
}
