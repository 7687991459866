.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    width: 620px;
    border-radius: 4px;
    box-sizing: border-box;
    padding-top: 36px;
    z-index: 10001;
    background: var(--intro-modal-bg);
    color: var(--intro-modal-color);

    @media (max-height: 400px) {
        scale: 0.8;
        bottom: 35px;
    }
}

.title {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 800;
    font-size: var(--desk-font-size-2-4);
    line-height: 130%;
    text-align: center;
    margin-bottom: 12px;
}

.subtitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-5);
    line-height: 130%;
    text-align: center;
    margin-bottom: 20px;
    max-width: 400px;
}

.hint {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-5);
    line-height: 130%;
    text-align: center;
    text-decoration-line: underline;
    margin-bottom: 26px;
    cursor: pointer;

    &:hover {
        color: var(--intro-final-modal-skip-button-hover-color);
    }
}

.button {
    width: 212px;
    height: 42px;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px;
    font-size: var(--desk-font-size-1-6);
    font-weight: 600;
    color: var(--intro-first-step-button-color);
    background: var(--intro-first-step-button-bg);

    &:hover {
        background: var(--intro-first-step-button-hover-bg);
    }
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.closeIcon {
    display: flex;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    background-image: var(--intro-close-icon-url);

    &:hover {
        background-image: url("/images/close_cross_pale.svg");
    }
}