.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    display: flex;
    justify-content: space-between;
    padding: 6px 15px;
    height: var(--asset-list-margin-info-mbl-height);
    background: var(--asset-list-margin-info-mbl-bg);
    color: var(--asset-list-margin-info-mbl-color);
    font-size: var(--desk-font-size-1);
    line-height: 14px;
    font-weight: 500;
    gap: 5px;

    .leftInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .titles {
            display: flex;
            flex-direction: column;

            .title {
                white-space: nowrap;
            }
        }

        .values {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 60px;

            &.long_value {
                width: 50px;
            }
        }

        &.tablet {
            width: 40%;
        }
    }

    .rightInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .titles {
            display: flex;
            flex-direction: column;

            .title {
                white-space: nowrap;
            }
        }

        .values {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 60px;

            &.long_value {
                width: 50px;
            }
        }

        &.tablet {
            width: 40%;
        }
    }
}