.container {
    margin-top: 6px;
    margin-bottom: 4px;
    color: #89909c;
    font-weight: 600;
    font-size: var(--desk-font-size-1-1);
    line-height: 130%;
    border: 1px solid #89909c;
    border-radius: 2px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    gap: 8px;

    .checkbox_block {
        display: flex;
        align-items: center;

        label {
            cursor: pointer;
            display: flex;
            align-items: center;
            color: var(--trades-section-selected-trade-profit-loss-color);
        }

        .label_disabled {
            color: var(--trades-section-selected-trade-profit-loss-disabled-color);
        }

        .sl_tp_toggle {
            min-width: 18px;
        }
    }

    >* {
        &:first-child {
            width: 45%;
        }

        &:nth-child(2) {
            width: 10%;
        }

        &:last-child {
            width: 45%;
        }
    }

    &.long_title {
        >* {
            &:first-child {
                width: 42%;
            }

            &:nth-child(2) {
                width: 16%;
            }

            &:last-child {
                width: 42%;
            }
        }
    }
}

.calculateProfitInput_dark,
.calculateProfitInput_light {
    input {
        color: var(--number-input-color);
        font-weight: 700;
        border-radius: 2px;
        padding: 4px 8px;
        border: 1px solid var(--number-input-focus-bg);
        background-color: var(--number-input-disabled-color);
    }

    &.disabled {
        pointer-events: none;
    }
}

.calculateProfitInput_light {
    input {
        background-color: var(--number-input-color-bg);
        color: var(--number-input-active-bg);
    }
}

.amountInput {
    font-size: var(--desk-font-size-1-3);

    &.disabled {
        pointer-events: none;
    }
}