.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc((var(--vh, 1vh) * 100));
    background-color: var(--bg) !important;

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--mbl-font-size-md);
        font-weight: 500;
        height: 42px;
        margin-top: 8px;
        margin-bottom: 6px;
        position: relative;

        .backIcon {
            position: absolute;
            top: 0px;
            left: 15px;
            width: 24px;
            height: 24px;
            transform: rotate(90deg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 21px;
            background-image: var(--asset-panel-back-icon);
        }

        .favContainer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .favoritesIcon {
            position: absolute;
            top: 1px;
            right: 18px;
            display: flex;
            height: 24px;
            width: 24px;
            background-repeat: no-repeat;
            background-position: center;
            color: #89909c;
            padding: 0;
            background-image: url("/images/star_grey.svg");

            &.favoritesIconChecked {
                color: goldenrod;
                background-image: url("/images/star_golden.svg");
            }
        }

        .infoBlock {
            text-align: center;

            .symbolName {
                font-weight: 300;
                font-size: var(--mbl-font-size-xl);
                line-height: 24px;
            }

            .details {
                display: flex;
                gap: 7px;

                .priceValue {
                    font-size: var(--mbl-font-size-xs);
                    line-height: 16px;
                    width: 44px;
                    height: 18px;

                    display: flex;
                    align-items: center;
                }

                .changeValue {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-radius: 12px;
                    font-size: var(--mbl-font-size-xsm);
                    line-height: 14px;
                    padding: 1px 8px;
                    color: #16bb6f;
                    background-color: rgba(22, 187, 111, 0.1);
                }
            }
        }
    }

    .defaultMaxHeight {
        max-height: 100px;
    }

    .graph_container {
        background-color: lightgray;
    }

    .actionsPanel {
        .tabs_panel {
            display: flex;
            padding: 8px 15px;
            gap: 8px;
            height: 56px;
            background-color: var(--asset-panel-bg);
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .tab {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: var(--asset-panel-action-tab-bg);
                border-radius: 5px;
                padding: 8px 10px;
                gap: 8px;
                min-width: 44px;
                height: 40px;
                line-height: 20px;
                font-size: var(--mbl-font-size-md);

                &.active {
                    background-color: var(--asset-panel-action-active-tab-bg);
                }

                &.sl_tp_active_dark {
                    >img {
                        filter: invert(31%) sepia(68%) saturate(3575%) hue-rotate(200deg) brightness(104%) contrast(104%);
                    }
                }

                &.sl_tp_active_light {
                    >img {
                        filter: invert(0%) sepia(91%) saturate(7500%) hue-rotate(34deg) brightness(101%) contrast(107%);
                    }
                }

                &.clock_icon_dark,
                &.clock_icon_light {
                    color: #ffffff;
                    gap: 6px;
                    min-width: 96px;
                    white-space: nowrap;
                }

                &.clock_icon_light {
                    color: #343841;

                    >img {
                        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(76deg) brightness(101%) contrast(102%);
                    }
                }

                &.text_icon {
                    color: var(--asset-panel-text-icon-color);
                    padding: 10px;
                    min-width: 76px;
                    text-align: center;
                    white-space: nowrap;
                }

                &.long_text_icon {
                    min-width: 95px;
                }

                @media (min-width: 768px) {
                    &:not(:first-child) {
                        flex-grow: 1;
                    }
                }
            }

            .svg_icon_light {
                filter: invert(18%) sepia(13%) saturate(657%) hue-rotate(183deg) brightness(95%) contrast(86%);
            }

            .svg_icon_dark {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(76deg) brightness(101%) contrast(102%);
            }

            .bull_bear_icon {
                min-width: 55px;
                padding: 8px 5px;

                >img {
                    width: 45px;
                    height: 24px;
                }
            }
        }

        .footer__buySellButtons {
            display: flex;
            justify-content: center;
            margin: 15px 15px 35px 15px;
            gap: 15px;
            height: 88px;
        }
    }
}

.tradingOrderFormMobile {
    margin-top: 24px !important;
    margin-bottom: 35px !important;
}

.container {
    background-color: #1c1f25;
}

.chart {
    background-color: var(--bg);
}

.calculatorContainer {
    margin-top: 24px;
}

.loaderWrp {
    position: absolute;
    width: 100%;
    height: calc((var(--vh, 1vh) * 68));
    display: flex;
    background-color: var(--bg);
    z-index: 1500;
    top: 56px;
}