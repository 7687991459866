.container {
    display: grid;
    grid-template-rows: 50px 44px 100px 65px 30px;
    position: absolute;
    width: 877px;
    height: 314px;
    left: 40px;
    box-sizing: border-box;
    bottom: 400px;
    border-radius: 4px;
    padding: 12px 24px;
    z-index: 1400;
    background: var(--intro-modal-bg);

    @media (max-width: 950px) {
        scale: 0.9;
        left: 0;
    }

    @media (max-width: 830px) {
        scale: 0.8;
    }

    @media (max-height: 730px) {
        bottom: 335px;
        scale: 0.7;
    }
}

.title {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 700;
    font-size: var(--desk-font-size-2-4);
    line-height: 130%;
    color: var(--intro-modal-color);
    margin: 10px 0 12px 20px;
    max-width: 780px;
}

.subtitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-5);
    line-height: 130%;
    margin: 0 0 12px 20px;
    max-width: 780px;
    color: var(--intro-modal-color);
}

.table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    height: 60px;
    grid-gap: 30px;
    margin: 0 auto;
    max-width: 780px;
}

.table_cell {
    display: grid;
    grid-template-columns: 48px 1fr;
    align-items: flex-start;
    justify-content: center;
    height: 60px;
    color: var(--intro-modal-color);

    > img {
        max-width: 38px;
    }
}

.table_cell_title {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-6);
    line-height: 125%;
}

.close {
    // width: 12px;
    // height: 12px;
    // background-image: url("/images/white_cross.svg");
    background-position: center;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.closeIcon {
    display: flex;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    background-image: var(--intro-close-icon-url);

    &:hover {
        background-image: url("/images/close_cross_pale.svg");
    }
}
