.arrow_icon {
    width: 20px;
    height: 20px;
    background: var(--date-range-mbl-calendar-arrow-up-icon-bg);
    border: 2px solid var(--date-range-mbl-calendar-arrow-up-icon-bg);
    border-radius: 4px;

    &.up {
        background-image: var(--date-mbl-calendar-arrow-down-btn-url);
        background-position: center;
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    &.down {
        background-image: var(--date-mbl-calendar-arrow-down-btn-url);
        background-position: center;
    }
}
