.container {
    height: calc((var(--vh, 1vh) * 100));
    display: flex;
    flex-direction: column;
}

.header {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--trades-section-selected-trade-header-mbl-bg);
    color: var(--trades-section-selected-trade-header-mbl-color);
    font-weight: 500;
    font-size: var(--desk-font-size-1-4);

    .iconBack,
    .iconBack_light {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 16px;
        transform: rotate(90deg);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("/images/arrow_down_black.svg");
    }

    .iconBack_light {
        background-image: url("/images/arrow_down.svg");
    }
}

.infoContainer {
    padding: 16px 15px 13px 15px;
}

.graph_container {
    height: calc((var(--vh, 1vh) * 100) - 18px - 59px - 44px - 203px);
}

.p_l_container {
    display: flex;
    height: 44px;
    min-height: 44px;
    border-top: 1px solid var(--profit-loss-border-color-mbl);
    align-items: center;

    .p_l_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;

        .p_l_title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: var(--desk-font-size-1);
            line-height: 14px;
            text-align: center;
            color: #9098ad;
        }
    }

    .separator {
        height: 24px;
        width: 1px;
        background-color: var(--profit-loss-separator-color-mbl);
    }



    .profited {
        color: #16bb6f;
    }

    .lossing {
        color: #f25555;
    }
}

.profitLossContainer {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    background: var(--trades-section-selected-trade-actions-mbl-bg);
    padding: 16px 15px;
}

.actionContainer {
    padding: 0 16px 11px;
    background: var(--trades-section-selected-trade-actions-mbl-bg);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    margin-top: -1px;

    .btn {
        height: 40px;
        width: 100%;
        border-radius: 8px;
        background-color: transparent;
        font-weight: 600;
        font-size: var(--desk-font-size-1-2);
        line-height: 16px;
        text-align: center;
        color: #ffffff;
    }

    .btnTransparent {
        border: 1px solid var(--app-modal-close-trade-btn-transparent-border-color);
        color: var(--app-modal-close-trade-btn-transparent-color);
    }

    .btnApply {
        background: var(--trades-section-selected-trade-btn-apply-mbl-bg);
        color: var(--trades-section-selected-trade-btn-apply-mbl-color);
        border: none;
    }

    .btnApply_disabled {
        background: var(--trades-section-selected-trade-btn-apply-disabled-mbl-bg);
        color: var(--trades-section-selected-trade-btn-apply-disabled-mbl-color);
        opacity: 1;
    }
}
