.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 760px;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 36px 12px 12px 12px;
    background: var(--intro-modal-bg);
    position: absolute;
    left: 488px;
    top: 176px;
    color: var(--intro-modal-color);

    @media (max-width: 1250px) {
        width: 600px;
    }

    @media (max-width: 1090px) {
        width: 400px;
    }

    @media (max-height: 700px) {
        top: 120px;
    }

    @media (max-height: 625px) {
        top: 40px;
    }

    @media (max-height: 530px) {
        scale: 0.8;
        top: 0px;
    }

    .title {
        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 700;
        font-size: var(--desk-font-size-2-4);
        line-height: 130%;
        text-align: center;
        margin-bottom: 12px;
    }

    .subtitle {
        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 600;
        font-size: var(--desk-font-size-1-5);
        line-height: 130%;
        text-align: center;
        margin-bottom: 28px;
    }

    .chips_container {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        max-width: 680px;
        margin-bottom: 30px;
    }

    .chips {
        display: flex;
        box-sizing: border-box;
        border: 2px solid #89909c;
        font-family: var(--main-font-family);
        font-style: normal;
        font-weight: 500;
        font-size: var(--desk-font-size-1-6);
        line-height: 125%;
        padding: 4px 14px;
        border-radius: 2.2rem;
        margin: 6px 8px;
        justify-content: center;
        align-items: center;

        >img {
            margin-right: 8px;
        }
    }

    .closeIcon {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
        display: flex;
        width: 28px;
        height: 28px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        background-image: var(--intro-close-icon-url);

        &:hover {
            background-image: url("/images/close_cross_pale.svg");
        }
    }
}