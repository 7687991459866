.container {
    position: relative;
    background: var(--app-input-number-mbl-bg);
    border: 1px solid var(--app-input-number-mbl-bg);
    border-radius: 5px;
    width: 164px;
    height: 40px;

    &.fullWidth {
        width: 100%;
    }
    &.hasError {
        border: 1px solid #F25555;
    }

    &.disabled {
        background: var(--app-input-number-mbl-disabled-bg);

        .value {
            color: var(--app-input-number-mbl-disabled-color);
        }

        .decrease,
        .increase,
        .decrease_light,
        .increase_light {
            width: 24px;
            height: 24px;
        }

        .decrease {
            background-image: url("/images/minusDark.svg");
        }

        .increase {
            background-image: url("/images/plusDark.svg");
        }

        .increase_light {
            background-image: url("/images/plusGrey.svg");
        }

        .decrease_light {
            background-image: url("/images/minusGrey.svg");
        }
    }
}

.value {
    text-align: center;
    padding: 9px 40px;
    font-weight: 500;
    font-size: var(--mbl-font-size-md);
    line-height: 20px;
    color: var(--app-input-number-mbl-color);
    background-color: var(--app-input-number-mbl-bg);
    outline-width: 0;
    border-width: 0;
    width: 100%;
    border-radius: 5px;
}


.increase,
.decrease,
.increase_light,
.decrease_light {
    position: absolute;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/images/minusBlack.svg");
    left: 10px;
    top: 8px;
}

.increase,
.increase_light {
    right: 10px;
    left: auto;
}

.increase {
    background-image: url("/images/plusBlack.svg");
}

.increase_light {
    background-image: url("/images/plusLight.svg");
}

.decrease_light {
    background-image: url("/images/minus.svg");
}
