.container {
    position: relative;
    user-select: none;
    margin: 5px 0 16px;

    &.fullWidth {
        width: 100%;
    }
}

.label {
    position: absolute;
    left: 9px;
    top: -7px;
    font-weight: 600;
    font-size: var(--desk-font-size-1-3);
    line-height: 130%;
    background-color: var(--bg);
    padding: 0 4px;
    pointer-events: none;
}

.label_dark {
    color: #ffffff;
}
.label_light {
    color: #000;
}
.error_container {
    position: absolute;
    display: flex;
    font-weight: 600;
    font-size: var(--desk-font-size-1-1);
    line-height: 130%;
    color: #f25555;
    left: 14px;
    padding-top: 2px;
}
.input {
    height: 46px;
    width: 100%;
    font-size: var(--desk-font-size-1-8);
    font-weight: 700;
    line-height: 100%;
    padding: 11px 8px 8px 12px;
    background: transparent;
    outline: none;
    border: 1px solid #89909c;
    border-radius: 8px;

    &.error {
        border: 1px solid #f25555;
    }

    &:focus,
    &:hover {
        border-color: #e6ecf1;
        &.error {
            border-color: #f25555;
        }
    }

    .disabled & {
        color: #555965;
        border-color: #555965;
    }

    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.input_light {
    color: #000;
    border: 1px solid #a7aebb;
    &:hover {
        border-color: #000000;
    }
}

.input_dark {
    color: #ffffff;
}

.emptyValue {
    color: var(--lot-size-input-empty-value-color);
}

.actions {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
    display: flex;
    justify-content: space-between;
    width: 65px;
    height: 30px;
    margin: auto;
    background: transparent;
    z-index: 3;
}
.increase_dark,
.increase_light,
.decrease_dark,
.decrease_light {
    position: relative;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    .disabled & {
        cursor: default;
        pointer-events: none;
        border-color: #555965;
    }
}
.increase_dark,
.decrease_dark {
    background-color: #353840;
    &:hover {
        background-color: #6e7583;
    }
}
.increase_light,
.decrease_light {
    background-image: url("/images/lotsize_arrow_left_black.svg");
    background-size: 12px 17px;
    background-position: center;

    background-repeat: no-repeat;
    background-color: #e5ecf2;
    > img {
        display: none;
    }
    &:hover {
        background-color: #c8d0d9;
    }
}
.increase_light {
    transform: rotate(90deg);
}
.decrease_light {
    transform: rotate(270deg);
}

.decrease_light,
.decrease_dark {
    img {
        transform: rotateZ(180deg);
    }
}
