.container {
    position: relative;
    border-radius: 1.7rem;
    width: 100%;
    height: 3.4rem;

    &._dark {
        background-color: #101115;
        border: 1.5px solid #101115;

        &:hover {
            border: 1.5px solid #101115;
        }
    }

    &._light {
        background-color: #ffffff;
        border: 1.5px solid #a7aebb;

        &:hover {
            border: 1.5px solid #000;
        }
    }

    .searchInput {
        text-indent: 1rem;
        border-radius: 1.7rem;
        color: inherit;
        width: 100%;
        height: 3.4rem;
        font-size: var(--desk-font-size-1-4);
        line-height: 18px;

        :global(.MuiInputBase-input) {
            font-size: var(--desk-font-size-1-4);
            font-family: var(--main-font-family);
            line-height: 18px;
            padding: 0 2rem;
            width: 100%;
            height: 100%;
            position: relative;
            bottom: 2px;

            &._dark {
                color: #e6ecf1;

                &:hover {
                    &::placeholder {
                        color: #E6ECF1 !important;
                    }
                }

            }

            &._light {
                color: #101115;

                &:hover {
                    &::placeholder {
                        color: #101115 !important;
                    }
                }

            }

            &::placeholder,
            ._light {
                color: #9098ad;
                opacity: 1;
                font-size: 1.4rem;
            }

            &::placeholder,
            ._dark {
                color: #89909c;
                opacity: 1;
                font-size: 1.4rem;
            }
            &:focus:not(:focus-visible) {
                outline: none;
            }
        }

        &.searchIconHoverActive {
            :global(.MuiInputBase-input) {

                &::placeholder,
                ._dark {
                    color: #e6ecf1;
                }

                &::placeholder,
                ._light {
                    color: #101115;
                }
            }
        }
    }

    .iconWrapper {
        padding: 0 2rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;

        border-radius: 0 39px 39px 0;
        display: grid;
        place-items: center;

        &._dark {
            background-color: #101115;
        }

        &._light {
            background-color: #ffffff;
        }

        .search_icon {
            width: 16px;
            height: 16px;

            &._dark {
                background-image: url("/images/search.svg");
            }

            &._light {
                background-image: url("/images/search_grey.svg");
            }

            background-repeat: no-repeat;
            background-position: center;
            cursor: text;
        }
    }

    .closeButton {
        border: none;
        width: 14px;
        height: 14px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 13.06px;
        background-color: transparent;
        cursor: pointer;

        &._dark {
            background-image: url("/images/search-close-cross-white.svg");
        }

        &._light {
            background-image: url("/images/search-close-cross-black.svg");
        }
    }
}