.info {
    padding: 0 15px;
    overflow-y: auto;
    margin-right: 4px;
}

.schedule {
    display: flex;
    justify-content: space-between;
    padding: 16px 8.5px;
}

.scheduleTitle {
    font-weight: 600;
    font-size: var(--desk-font-size-1-2);
    line-height: 16px;
}

.scheduleHourList {
    font-weight: 500;
    font-size: var(--desk-font-size-1-2);
    line-height: 16px;
}

.scrollArea_dark, .scrollArea_light {
    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(187, 201, 213, 0.3);
        border-radius: 2.5px;
        height: 107px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--bg);
    }
}

.scrollArea_light {
    &::-webkit-scrollbar-thumb {
        background: rgba(187, 201, 213, 1);
    }
}

.listInfoMobile {
    margin-bottom: 0px;
}

.emptyLine {
    height: 16px;
}
