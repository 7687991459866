.container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background: var(--intro-modal-bg);
    position: absolute;
    width: 740px;
    top: 170px;
    border-radius: 4px;
    box-sizing: border-box;
    padding-top: 36px;
    z-index: 1306;
    color: var(--intro-modal-color);

    @media (max-width: 1525px) {
        width: 600px;
    }

    @media (max-width: 1325px) {
        width: 500px;
    }

    @media (max-width: 1200px) {
        width: 350px;
    }

    @media (max-height: 1050px) {
        top: 90px;
    }

    @media (max-height: 470px) {
        scale: 0.9;
        top: 30px;
    }

    .content {
        padding: 0 24px;

        .title {
            font-family: var(--main-font-family);
            font-style: normal;
            font-weight: 700;

            font-size: var(--desk-font-size-2-4);
            line-height: 130%;
            margin-bottom: 12px;
        }

        .subtitle {
            font-family: var(--main-font-family);
            font-style: normal;
            font-weight: 600;
            font-size: var(--desk-font-size-1-5);
            line-height: 30px;
            margin-bottom: 20px;
            text-align: left;
            line-height: 130%;

            display: flex;
            flex-direction: column;
            gap: 2px;

            .row {
                display: flex;
                gap: 5px;
            }
        }
    }

    .closeIcon {
        position: absolute;
        top: 12px;
        right: 12px;
        cursor: pointer;
    }
}

.closeIcon {
    display: flex;
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    background-image: var(--intro-close-icon-url);

    &:hover {
        background-image: url("/images/close_cross_pale.svg");
    }
}
