.container {
    width: calc(100% - 2rem);
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    &:not(.partialCloseOpen) {
        margin-top: -44px;
        /* half of partial close form height */
    }

    @media (min-width: 768px) {
        width: 345px;
    }

    @media (max-height: 500px) and (orientation: landscape) {
        top: 1rem;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        margin: 0 auto;
        overflow: auto;
    }

    .title {
        font-weight: 600;
        font-size: var(--desk-font-size-2);
        line-height: 24px;
        text-align: center;
        margin-top: 8px;
    }

    .question {
        font-weight: 500;
        font-size: var(--desk-font-size-1-4);
        line-height: 20px;
        text-align: center;
        margin-top: 8px;
    }
}

.infoContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 9px;
    margin-top: 20px;

    .infoItem {
        background: var(--trades-section-list-item-info-mbl-bg);
        height: 72px;
        padding: 12px;
        border-radius: 5px;
        text-align: center;
        font-weight: 500;
        font-size: var(--desk-font-size-1-2);
        line-height: 16px;

        &__value {
            font-weight: 600;
            font-size: var(--desk-font-size-2);
            line-height: 24px;
            margin-bottom: 8px;

            &.profit {
                color: #16bb6f;
            }

            &.loss {
                color: #f25555;
            }
        }
    }
}

.toggleContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    font-weight: 500;
    font-size: var(--desk-font-size-1-6);
    line-height: 24px;
    border-bottom: 1px solid var(--app-modal-close-trade-line-color);
    padding-bottom: 15px;
    padding-left: 12px;

    .customToggle {
        background: var(--toggle-close-order-bg);
    }
}

.buttonsContainer {
    padding-top: 56px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;

    button:disabled {
        opacity: 1;
    }

    .btn {
        height: 48px;
        width: 100%;
        border-radius: 8px;
        background-color: transparent;
        font-weight: 600;
        font-size: var(--desk-font-size-1-2);
        line-height: 16px;
        text-align: center;
        color: #ffffff;
    }

    .btnTransparent {
        border: 2px solid var(--app-modal-close-trade-btn-transparent-border-color);
        color: var(--app-modal-close-trade-btn-transparent-color);
    }

    .btnInfo {
        background-color: #007bff;
        border: 1px solid #007bff;

        &.disabled {
            background-color: var(--app-modal-close-trade-btn-disabled-bg);
            border: 1px solid var(--app-modal-close-trade-btn-disabled-bg);
            color: var(--app-modal-close-trade-btn-disabled-color);
        }
    }

    .touched_ok {
        background-color: #003166;
        border: 1px solid #003166;
    }

    .touched_no {
        background-color: var(--app-modal-close-trade-btn-transparent-pressed-bg);
        border: 1px solid transparent;
        color: var(--app-modal-close-trade-btn-transparent-pressed-color);
    }
}