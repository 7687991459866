.header_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px 0;
    height: 16px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    color: #000000;
    position: relative;

    .header_date_wrapper {
        display: flex;
        width: 84px;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, .12);
            height: 1.5em;
            padding: 0 0.5em;
            border-radius: 3px;
            transition: background-color .1s linear;
        }
    }

    .header_button {
        display: flex;
        height: 20px;
        width: 20px;
        border: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;

        &.right {
            position: absolute;
            top: -2px;
            right: 1px;
            background-image: url("/images/arrow_left_black.svg");
            transform: rotate(180deg);
            background-color: #fff;
            cursor: pointer;
        }

        &.left {
            position: absolute;
            top: -2px;
            left: 1px;
            background-image: url("/images/arrow_left_black.svg");
            background-color: #fff;
            cursor: pointer;
        }
    }

    .header_month {
        display: flex;
        width: 26px;
    }

    .header_year {
        display: flex;
        background-repeat: no-repeat;
        background-size: 9px;
        background-position: 100% 50%;
        width: 45px;
        background-image: url("/images/dropdown_arrow_black.svg");
    }

    .divider {
        position: absolute;
        bottom: -34px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #dbdbdb;
    }
}