.drawerWrp {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--bg) !important;
    height: 122px;
    padding: 4px 15px;
    box-sizing: border-box;
    top: calc((var(--vh, 1vh) * 100) - 147px) !important;

    &.isHidden {
        visibility: hidden !important;
    }
}

.tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 5px;
    min-width: 44px;
    height: 40px;
    line-height: 20px;
    font-size: var(--desk-font-size-1-6);
    gap: 10px;
    background-color: var(--pending-orders-trade-panel-item-bg);

    &.expanded {
        padding-left: 14px;
        min-width: auto;
        background-size: 20px;
        background-position: 12px 50%;
        border-radius: 5px;
        background-color: var(--pending-orders-trade-panel-item-expanded-bg);
    }

    .watchIcon {
        background-image: var(--pending-orders-svg-icon-url);
        background-repeat: no-repeat;
        background-size: 20px;
        width: 20px;
        height: 20px;
    }

    .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        word-break: keep-all;
        white-space: nowrap;
        flex: 1;
    }

    .calendarIcon {
        margin-left: 6px;
        width: 32px;
        height: 32px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: auto;
        background-color: var(--pending-orders-trade-panel-item-dots-bg);
        background-image: var(--pending-orders-trade-panel-item-dots-bg-img-url);

        &.active {
            background-image: var(--pending-orders-trade-panel-item-dots-active-bg-img-url);
        }
    }
}

.disabled {
    opacity: 0.4;
}

.accountInfoTitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-4);
    line-height: 24px;
    color: #ffffff;
}

.accountInfoSubtitle {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1);
    line-height: 14px;
    color: #9098ad;
}

.pendginOrderButtonText {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-4);
    line-height: 20px;
    color: #ffffff;
}

.controlsWrp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.controlsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;

    justify-items: center;
    margin: 16px 0;
    grid-gap: 6px;
}

.controlsContainer_tablet {
    grid-template-columns: 1fr 1fr 1fr;
}

.priceSetter {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    border: var(--pending-orders-pricesetter-border);
}

.setter {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 20px;
    border-radius: 50px;
    //background-color: var(--pending-orders-setter-bg); //#d8d8d8
}

.minus {
    background-image: var(--pending-orders-minus-bg-img-url);
}

.plus {
    background-image: var(--pending-orders-plus-bg-img-url);
}

.dataContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 94px;
}

.title_light,
.title_dark {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1);
    line-height: 14px;
}

.title_dark {
    color: #ffffff;
}

.title_light {
    color: #000000;
}

.amount_light,
.amount_dark {
    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 500;
    font-size: var(--desk-font-size-1-4);
    line-height: 20px;
    text-align: center;
    width: 90px;
    background-color: transparent;
    border: none;
}

.amount_light {
    color: #000;
}

.amount_dark {
    color: #fff;
}

.orderButton_dark,
.orderButton_light {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    border-radius: 5px;
    margin: 0 auto;

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1-6);
    line-height: 24px;
    color: #9098ad;
    text-align: center;
    pointer-events: none;

    &.active {
        background: var(--action-buy);
        color: #ffffff;
        pointer-events: all;
    }

    &.active_sell {
        background: var(--action-sell);
        color: #ffffff;
        pointer-events: all;
    }

    &.touched_buy {
        background: var(--action-buy-pressed);
    }

    &.touched_sell {
        background: var(--action-sell-pressed);
    }
}

.orderButton_dark {
    background-color: #343841;
}

.orderButton_light {
    background-color: #e0e5f0;
}

.disabled {
    opacity: 0.5;
}

._button {
    display: flex;
    width: 100%;
    // max-width: 92px;
    min-width: 54px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: 700;
    padding: 3px;
    border: 2px solid;
    border-radius: 8px;

    font-family: var(--main-font-family);
    font-style: normal;
    font-weight: 600;
    font-size: var(--desk-font-size-1);
    line-height: 14px;

    text-align: center;

    cursor: pointer;

    &.active {
        cursor: default;
    }

    &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
    }

    &.buy {
        color: var(--action-buy);
        border-color: var(--action-buy);

        &:before {
            right: 8px;
            top: 8px;
            border-width: 0 13px 13px 0;
            border-color: transparent var(--action-buy) transparent transparent;
        }

        &.active {
            background: var(--action-buy);
            color: var(--trading-order-buy-sell-hover-color);

            &:before {
                border-color: transparent var(--trading-order-buy-sell-hover-color) transparent transparent;
            }
        }
    }

    &.sell {
        color: var(--action-sell);
        border-color: var(--action-sell);

        &:before {
            left: 8px;
            bottom: 8px;
            border-width: 13px 0 0 13px;
            border-color: transparent transparent transparent var(--action-sell);
        }

        &.active {
            background: var(--action-sell);
            color: var(--trading-order-buy-sell-hover-color);

            &:before {
                border-color: transparent transparent transparent var(--trading-order-buy-sell-hover-color);
            }
        }
    }
}

.sell_disabled {
    color: var(--trading-order-buy-sell-disabled) !important;
    border: 1.5px solid var(--trading-order-buy-sell-disabled) !important;

    &:before {
        border-color: transparent transparent transparent var(--trading-order-buy-sell-disabled) !important;
    }
}

.buy_disabled {
    color: var(--trading-order-buy-sell-disabled) !important;
    border: 1.5px solid var(--trading-order-buy-sell-disabled) !important;

    &:before {
        border-color: transparent var(--trading-order-buy-sell-disabled) transparent transparent !important;
    }
}