.drawerWrp {
    box-shadow: none !important;
}

.container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 147px;
    color: var(--color);
    background: var(--bg);

    overflow-x: scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .cards {
        display: flex;
        gap: 10px;
        padding-top: 16px;
        padding-left: 15px;

        .card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            min-width: 90px;
            min-height: 104px;
            border-radius: 5px;
            padding: 10px;
            border: 2px solid var(--time-frame-mbl-btn-border);
            font-size: var(--desk-font-size-1);
            line-height: 14px;

            &.selected {
                background-color: var(--time-frame-mbl-btn-selected-bg);
                color: var(--time-frame-mbl-btn-selected-color);
                border: 2px solid transparent;
            }

            .value {
                font-weight: 600;
                font-size: var(--desk-font-size-2-4);
                line-height: 32px;
                padding: 5px;
            }

            .value_measure {
                font-size: var(--desk-font-size-1-2);
                line-height: 16px;
                padding: 5px;
            }
        }
    }
}