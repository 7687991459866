.container {
    display: grid;
    grid-template-columns: repeat(6, 40px);
    grid-gap: 10px;
    height: 100%;
    align-content: center;

    @media (max-width: 1366px) {
        grid-template-columns: repeat(6, 32px);
        grid-gap: 8px;
    }

    &.isDevmode {
        grid-template-columns: repeat(7, 40px);
    }
}

.listItem {
    position: relative;
}

.listCount {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: var(--action-sell);
    font-weight: 600;
    overflow: hidden;
    right: 0;
    color: #fff;
    line-height: 11px;
    font-size: var(--desk-font-size-1);
    border-radius: 0.3rem;
    top: -0.6rem;
    max-width: 24px;
    height: 13px;
    padding: 1px 4px;
    &.lock {
        width: 14px;
        height: 13px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 9px 11px;
        background-image: url("/images/lock_white.svg");
    }

    &.reload {
        width: 14px;
        height: 13px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 9px 11px;
        background-image: url("/images/reload-white.svg");
    }
}

.item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .img,
    .imgHover {
        display: flex;
        width: 40px;
        height: 40px;

        @media (max-width: 1366px) {
            width: 32px;
            height: 32px;
        }

        @media screen and (max-width: 1024px) {
            width: 25px;
            height: 25px;
        }
    }

    .imgHover {
        display: none;
    }

    .img_active {
        display: none;
    }

    .img_hover_active {
        display: block;
    }

    &:hover {
        .imgHover {
            display: block;
        }

        .img {
            display: none;
        }
    }

    &.active {
        .imgHover {
            display: block;
        }

        .img {
            display: none;
        }
    }
}

.tooltip {
    position: absolute;
    bottom: 75px;
    display: none;
    justify-content: center;
    color: #fff;
    font-family: "Inter";
    font-size: var(--desk-font-size-1-2);
    font-weight: 700;
    line-height: 16px;

    text-align: center;
    border-radius: 2px;

    &.noWrapText {
        max-width: 48px;
        min-height: 10px;
    }

    &.visible {
        display: flex;
    }

    .text {
        background-color: #000;
        display: block;
        padding: 5px;
        position: relative;
        white-space: nowrap;

        &.noWrapText {
            white-space: normal;
        }

        .triangle {
            position: absolute;
            transition: all 0.3s ease-in;
            bottom: -14px;
            left: calc(50% - 8px);
            border-top: 8px solid black;
            border-bottom: 8px solid transparent;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
        }
    }
}
