    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: var(--mbl-font-size-md);
        font-weight: 500;
        height: 42px;
        margin-top: 8px;
        margin-bottom: 6px;
        position: relative;

        .backIcon {
            position: absolute;
            top: 0px;
            left: 15px;
            width: 24px;
            height: 24px;
            transform: rotate(90deg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 21px;
            background-image: var(--asset-panel-back-icon);
        }

        .favContainer {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .favoritesIcon {
            position: absolute;
            top: 1px;
            right: 18px;
            display: flex;
            height: 24px;
            width: 24px;
            background-repeat: no-repeat;
            background-position: center;
            color: #89909c;
            padding: 0;
            background-image: url("/images/star_grey.svg");

            &.favoritesIconChecked {
                color: goldenrod;
                background-image: url("/images/star_golden.svg");
            }
        }

        .infoBlock {
            text-align: center;

            .symbolName {
                font-weight: 300;
                font-size: var(--mbl-font-size-xl);
                line-height: 24px;
            }

            .details {
                display: flex;
                gap: 7px;

                .priceValue {
                    font-size: var(--mbl-font-size-xs);
                    line-height: 16px;
                    width: fit-content;
                    height: 18px;

                    display: flex;
                    align-items: center;
                }

                .changeValue {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-radius: 12px;
                    font-size: var(--mbl-font-size-xsm);
                    line-height: 14px;
                    padding: 1px 8px;
                    color: #16bb6f;
                    background-color: rgba(22, 187, 111, 0.1);

                    &.negativeValue {
                            color: #F25555;
                            background-color: rgba(242, 85, 85, 0.1);
                        }
                }
            }
        }
    }