.main {
    display: grid;
    height: 28px;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;
    align-items: center;
    margin: 24px 0 16px 0;

    .decoratorLine {
        width: calc(100% - 14px);
        height: 2px;

        &.left {
            justify-self: end;
        }

        &.right {
            justify-self: start;
        }

        &.dark {
            background-color: rgba(76, 81, 94, 1);
        }
        &.light {
            background-color: rgba(224, 229, 240, 1);
        }
    }

    .wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 8px;
        .title {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            &.dark {
                color: #fff;
            }
            &.light {
                color: rgba(0, 0, 0, 1);
            }
        }
        .PL {
            display: flex;
            justify-content: center;
            margin: 0 0 0 8px;

            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;

            &.isProfit {
                color: rgba(22, 187, 111, 1);
            }
            &.isLoss {
                color: rgba(242, 85, 85, 1);
            }
        }
    }
}
