.container {
    position: relative;
    width: 20%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    border-right: 2px solid var(--layout-separator-color);

    &.isModalOpen {
        overflow: hidden;
        height: 100%;
    }
}

.overlayed {
    pointer-events: none;
    z-index: 999999;
    border: none;
}

.tabs {
    display: flex;
    width: 100%;

    .tab {
        flex-grow: 1;
        background: var(--trading-order-tabs-bg);
        color: var(--trading-order-tabs-color);
        font-size: var(--desk-font-size-1-6);
        line-height: 1.5;
        font-weight: 600;
        text-align: center;
        padding: 0.5rem 15px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            color: var(--trading-order-tabs-hover-color);
        }

        &.tabActive {
            background: var(--trading-order-tabs-active-bg);
            color: var(--trading-order-tabs-active-color);
        }
    }
}

.content {
    padding: 12px;
    overflow-y: auto;
}

.orderFormHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 13px 0 16px 0;
}

.assetTopInfo {
    width: 100%;
    display: flex;
    align-items: center;
}

.assetIcon {
    margin-right: 6px;

    img {
        width: 24px;
        vertical-align: top;
        position: relative;
        z-index: 2;

        &:last-child {
            margin-left: -6px;
            z-index: 1;
        }
    }
}

.assetName {
    font-size: var(--desk-font-size-2-2);
    line-height: 1;
    letter-spacing: -1px;
    color: var(--trading-order-top-info-asset-name-color);
}

.closeIcon {
    font-size: 16px;
    color: var(--trading-order-header-close-color);
}

.orderInfoList {
    margin: 0 0 8px 0;
    color: #89909c;
    font-family: var(--main-font-family);
    font-weight: 600;
    line-height: 16.9px;
}

.disabled {
    opacity: 0.4;
}

.orderInfoListRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: var(--trading-order-info-list-row-color);
}

.orderInfoListLabel_light,
.orderInfoListLabel_dark {
    text-align: left;
}

.orderInfoListValue_light,
.orderInfoListValue_dark {
    text-align: right;
}

.tradeButton {
    height: 50px;

    &:global(.MuiButtonBase-root) {
        font-family: var(--main-font-family);
        border-radius: 8px;
        font-size: var(--desk-font-size-2-4);
        font-weight: 800;
        line-height: 130%;
        text-transform: none;
        padding: 0.6rem 1rem;
        box-shadow: none !important;
        margin-top: 1rem;
    }
}

.tradeButton_dark {
    &:global(.MuiButtonBase-root) {
        background-color: var(--trading-order-submit-bg_dark);
        color: var(--trading-order-submit-color_dark);

        & :hover {
            background-color: var(--trading-order-submit-hover-bg_dark);
        }

        &[disabled] {
            background-color: var(--trading-order-submit-disabled-bg_dark);
            color: var(--trading-order-submit-disabled-color_dark);
            opacity: 1;
        }
    }
}

.tradeButton_light {
    &:global(.MuiButtonBase-root) {
        background-color: var(--trading-order-submit-bg-light);
        color: var(--trading-order-submit-color-light);

        & :hover {
            background-color: var(--trading-order-submit-hover-bg_light);
        }

        &[disabled] {
            background-color: var(--trading-order-submit-disabled-bg_light);
            color: var(--trading-order-submit-disabled-color_light);
            opacity: 1;
        }
    }
}

.container_dark {
    background-color: #1d1f24;
}

.container_light {
    background-color: #fff;
}

.buySellButtons {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 15px;
    gap: 15px;

    .pipsValue {
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translate(-50%, 0);
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px 10px 0 0;
        width: 30%;
        height: 20px;
        color: #9098ad;
        background-color: var(--bg);
        line-height: 15px;
        font-size: var(--desk-font-size-09);
        letter-spacing: 1px;

        &.tablet {
            font-size: var(--desk-font-size-1-2);
            padding-top: 6px;
        }
    }
}

.btn {
    padding: 12px 0 28px 0;
    height: 88px;

    &.buy {
        &:before {
            width: 13px;
            height: 13px;
        }
    }

    &.sell {
        &:before {
            width: 13px;
            height: 13px;
        }
    }

    .label {
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
    }

    .value {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }
}

.btn_disabled {
    opacity: 0.3;
    pointer-events: none;
}