.container {
    color: var(--trade-calculator-mbl-color);
    padding-bottom: 4px;
    background-color: var(--trade-calculator-mbl-bg);
    height: 230px;

    .display {
        position: relative;
        background: var(--trade-calculator-display-mbl-bg);
        padding: 1px 16px;
        height: 40px;

        .value {
            font-weight: 300;
            font-size: var(--mbl-font-size-xl2);
            line-height: 24px;
            min-height: 24px;
            text-align: center;
        }

        .cancelIcon,
        .cancelIcon_light {
            position: absolute;
            right: 12px;
            top: 8px;
            width: 24px;
            height: 24px;
            background-image: url("/images/x.svg");
        }
        .cancelIcon_light {
            background-image: url("/images/xBlack.svg");
        }

        .helpText {
            font-weight: 500;
            font-size: var(--mbl-font-size-xsm);
            line-height: 14px;
            text-align: center;
            color: #9098ad;

            &.helpTextError {
                color: #F25555;
            }
        }
    }

    .keyboard {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;

        button {
            position: relative;
            background-color: var(--trade-calculator-mbl-bg);
            outline: none;
            border-width: 0;
            color: var(--trade-calculator-mbl-color);
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: var(--mbl-font-size-lg);
            line-height: 20px;

            &:active {
                background-color: var(--trade-calculator-btn-active-mbl-bg);
            }
        }

        .backspaceIcon,
        .backspaceIcon_light {
            width: 36px;
            height: 36px;
            background-image: url("/images/undo.svg");
        }
        .backspaceIcon_light {
            background-image: url("/images/undoBlack.svg");
        }

        .volumeBlock {
            padding: 3px 8px;
            background: var(--trade-calculator-volume-mbl-bg);
            color: var(--trade-calculator-volume-mbl-color);
            border-radius: 5px;
            font-weight: 600;
            font-size: var(--mbl-font-size-xsm);
            line-height: 14px;
            text-align: center;
        }
    }
}
