.container {
    display: flex;
    margin-left: 28px;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .customToggle {
        background: var(--theme-switcher-toggle-bg);
    }

    @media (max-width: 1366px) {
        margin-left: 10px;
    }

    img {
        width: 26px;
        height: 26px;

        @media screen and (max-width: 1024px) {
            width: 20px;
            height: 20px;
        }
    }
}
