.container {
    @media screen and (max-width: 479px) {
        max-width: 100%;
        width: 100%;
    }

    position: relative;

    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
}

.overlayed {
    pointer-events: none;
    z-index: 1303;
}

.toolbar {
    display: flex;
    align-items: center;
    height: var(--header-toolbar-height);
    padding: 0 0 0 0.5rem;
    position: absolute;
    background: var(--bg);
    top: 0;
    width: 100%;
    z-index: 2;
}

.toolbar_mbl {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--bg);
    padding: 8px 15px 0 15px;
    display: grid;
    grid-template-columns: 1fr 62px 32px;
    column-gap: 0.8rem;

    .containerWithMarginBlock & {
        top: var(--asset-list-margin-info-mbl-height);
    }

    &.searchActive {
        grid-template-columns: 1fr;
        padding: 8px 15px 8px 15px;
    }
}

.searchInput {
    input {
        font-size: var(--desk-font-size-1-4) !important;
    }
}

.activeSearch {
    input {
        border-radius: 32px;
        outline: 5px solid var(--asset-list-mbl-search-outline) !important;
        border: 1.5px solid var(--asset-list-mbl-search-border);
        background-color: var(--asset-list-mbl-search-active-bg);
    }
}

.content {
    display: flex;
    width: 100%;
}

.content_mbl {
    display: block;
    // border-top: var(--trading-assets-section-mbl-border);
}

.expandButton {
    margin: 0 0.7rem;
    cursor: pointer;
    width: 20px;

    &:before {
        width: 20px;
        height: 20px;
        content: var(--asset-list-expand-icon-url);
    }

    &:hover {
        &:before {
            content: var(--asset-list-expand-icon-url-hover);
        }
    }

    img {
        display: block;
        width: 16px;
    }

    .expandIconHover {
        display: none;
    }

    &:hover {
        .expandIcon {
            display: none;
        }

        .expandIconHover {
            display: block;
        }
    }
}

.toolbar__btnContainer {
    display: flex;
    height: 32px;
    background: var(--asset-list-btn-container-bg);
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    padding: 3px;

    :not(:first-child) {
        margin-left: 4px;
    }

    .list1_icon,
    .list2_icon,
    .list2_icon_active,
    .list2_icon_active_light,
    .chart_icon,
    .list1_icon_light,
    .list2_icon_light,
    .chart_icon_light,
    .list1_inactiveIcon {
        background-repeat: no-repeat;
        background-position: center;
        width: 26px;
        height: 26px;
    }

    .icon {
        width: 16px;
        height: 16px;
    }

    .list1_inactiveIcon {
        background-image: url("/images/list-assets-1_inactive.svg");
    }

    .list1_icon {
        border-radius: 5px;
        box-shadow: 0px 1px 8px rgba(28, 31, 37, 0.60413);
        background-image: url("/images/list-assets-1.svg");
    }

    .list2_icon {
        background-image: url("/images/list-assets-2.svg");
    }

    .list2_icon_active {
        border-radius: 5px;
        box-shadow: 0px 1px 8px rgba(28, 31, 37, 0.60413);
        background-image: url("/images/list-assets-2_active.svg");
    }

    .list2_icon_active_light {
        border-radius: 5px;
        box-shadow: 0px 1px 8px rgba(149, 157, 177, 0.283572);
        background-image: url("/images/list-assets-2_active.svg");
    }

    .chart_icon {
        background-image: url("/images/chart.svg");
    }

    .list1_icon_light {
        border-radius: 5px;
        box-shadow: 0px 1px 8px rgba(149, 157, 177, 0.283572);
        background-image: url("/images/list-assets-1_light.svg");
    }

    .list2_icon_light {
        background-image: url("/images/list-assets-2_light.svg");
    }

    .chart_icon_light {
        background-image: url("/images/chart_light.svg");
    }

    .active {
        background: var(--asset-list-btn-active-bg);
        border-radius: 5px;
    }

    .activeChartTab_dark,
    .activeChartTab_light {
        background: var(--asset-list-chart-btn-active-bg);
        border-radius: 5px;
    }

    .activeChartTab_light {
        background: var(--asset-list-chart-btn-active-bg);
    }
}

.toolbar__chartBtnContainer_dark,
.toolbar__chartBtnContainer_light {
    background: #ffffff;

    .chart_icon {
        background-image: url("/images/chart_black.svg");
    }
}

.toolbar__chartBtnContainer_light {
    background: #4c515e;

    .chart_icon_light {
        background-image: url("/images/chart_white.svg");
    }
}

.categoryListWrap {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 10;
    top: var(--asset-list-toolbar-mbl-height);
    border-bottom: 1px solid var(--asset-list-table-border);

    .containerWithMarginBlock & {
        top: calc(var(--asset-list-margin-info-mbl-height) + var(--asset-list-toolbar-mbl-height));
    }

    width: 100%;
    background: var(--bg);

    @media screen and (max-width: 479px) {
        padding: 16px 0 15px 15px;
    }

    .categoryList {
        position: relative;
        min-height: var(--asset-categories-height);

        @media screen and (max-width: 479px) {
            min-height: var(--asset-categories-mbl-height);
            margin-top: 0.4rem;
        }

        :global(.MuiTabs-flexContainer) {
            @media screen and (max-width: 479px) {
                padding: 0;
            }

            gap: 6px;
            padding: 16px 8px;
            width: 100%;
            margin: 0;
        }

        :global(.MuiTabScrollButton-horizontal) {
            @media screen and (max-width: 479px) {
                height: var(--asset-categories-mbl-height);
            }

            position: absolute;
            height: var(--asset-categories-height);
            z-index: 2;
            opacity: 1;

            :global(.MuiSvgIcon-root) {
                font-size: var(--desk-font-size-1-4);
                width: 2em;
                height: 2em;
            }

            &:global(.Mui-disabled) {
                opacity: 0;
                pointer-events: none;
            }

            &:first-child {
                left: 0;
                background: var(--asset-list-category-nav-prev-bg);
            }

            &:last-child {
                right: 0;
                background: var(--asset-list-category-nav-next-bg);
            }
        }

        .categoryListItem_dark,
        .categoryListItem_light {
            @media screen and (max-width: 479px) {
                min-height: var(--asset-categories-mbl-height);
                font-weight: 500;
                font-size: var(--mbl-font-size-xs);
                line-height: 16px;
                padding: 4px 9px;
                color: var(--asset-list-category-hover-color);
            }

            &.tablet {
                padding: 4px 10px;
                color: var(--asset-list-category-hover-color-black);
            }

            font-family: var(--main-font-family);
            font-size: var(--desk-font-size-1-5);
            line-height: 1.2;
            font-weight: 600;
            border-radius: 80px;
            text-transform: none;
            letter-spacing: normal;
            height: 26px;
            padding: 0 8px;
            min-height: var(--asset-categories-height);
            max-height: var(--asset-categories-height);
            min-width: 0;
            background-color: var(--asset-list-category-bg);
            color: var(--asset-list-category-color);
            border: 1.5px solid var(--asset-list-category-border-mobile);

            &.categoryListItemActive {
                background-color: var(--asset-list-category-active-bg);
                color: var(--asset-list-category-active-color);
                border-color: var(--asset-list-category-bg);

                &:hover {
                    color: var(--asset-list-category-active-color);
                }
            }
        }

        .categoryListItem_light {
            @media screen and (max-width: 479px) {
                color: var(--asset-list-category-hover-color-black);
            }
        }
    }
}

.tableWrap {
    width: 100%;
    margin: 0;
}

.tableWrap_dark {
    background-color: #1d1f24;
}

.tableWrap_light {
    background-color: #fff;
}

.assetsListContainer_mbl_dark,
.assetsListContainer_mbl_light {
    width: 100%;
    position: fixed;
    top: calc(var(--asset-list-toolbar-mbl-height) + var(--asset-categories-container-mbl-height));
    height: calc(
        100 * var(--vh) - var(--footer-mbl-height) - var(--asset-list-toolbar-mbl-height) -
            var(--asset-categories-container-mbl-height)
    );
    overflow-y: auto;
    overscroll-behavior-y: contain;

    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(
    //         100% - var(--footer-mbl-height) - var(--asset-list-toolbar-mbl-height) -
    //             var(--asset-categories-container-mbl-height)
    //     );
    // }
}

.listContainerWithSearch {
    top: var(--asset-list-active-search-toolbar-mbl-height);
    height: calc(
        100 * var(--vh) - var(--footer-mbl-height) - var(--asset-list-active-search-toolbar-mbl-height)
    );
}

.listContainerWithMarginBlock {
    // @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //     height: calc(
    //         100vw - 130px - var(--asset-list-toolbar-mbl-height) -
    //             var(--asset-categories-container-mbl-height)
    //     ) !important;
    // }

    top: calc(
        var(--asset-list-margin-info-mbl-height) + var(--asset-list-toolbar-mbl-height) +
            var(--asset-categories-container-mbl-height)
    );
    height: calc(
        100 * var(--vh) - var(--footer-mbl-height) - var(--asset-list-margin-info-mbl-height) -
            var(--asset-list-toolbar-mbl-height) - var(--asset-categories-container-mbl-height)
    );
}

.listContainerWithMarginBlockAndSearch {
    top: calc(
        var(--asset-list-margin-info-mbl-height) + var(--asset-list-active-search-toolbar-mbl-height)
    );
    height: calc(
        100 * var(--vh) - var(--footer-mbl-height) - var(--asset-list-margin-info-mbl-height) -
            var(--asset-list-active-search-toolbar-mbl-height)
    );
}
