.inputWrapper {
    position: relative;
    display: flex;
    border: 1px solid var(--date-input-border);
    border-radius: 2px;
    max-width: 176px;
    max-height: 24px;

    [class*="react-datepicker__header"] {
        background-color: #ffffff;
    }

    &:hover {
        transition: 0.3s;
        background: var(--date-input-hover-bg);
        color: var(--date-input-hover-color);
        border-color: var(--date-input-hover-border);

        &::placeholder {
            color: var(--date-input-hover-color);
        }

        .calendar_icon {
            background-image: var(--datepicker-icon-url) !important;
        }

        .dateInput {
            color: var(--date-input-hover-color);
        }
    }

    .dateInput {
        display: flex;
        align-items: center;
        border: none;
        border-radius: 2px;
        font-weight: 700;
        font-size: var(--desk-font-size-1-2);
        line-height: 130%;
        padding: 5px 20px 5px 8px;
        width: 100%;
        height: 100%;
        box-shadow: none;
        outline: none;
        color: var(--date-input-color);
        background: transparent;

        &::placeholder {
            color: var(--date-input-color);
        }
    }

    .calendar_icon {
        position: absolute;
        min-width: 20px;
        height: 20px;
        background-size: 14px;
        background-repeat: no-repeat;
        right: 0px;
        top: 1px;
        background-image: var(--datepicker-icon-url);
        background-position: center;

        &:hover {
            cursor: pointer;
        }
    }
}

.calendarContainer {
    position: relative;
    width: 222px;
    height: 258px;
    background-color: #ffffff;
    filter: drop-shadow(0px 5px 20px rgba(52, 56, 65, 0.5));

    @media screen and (max-width: 1366px) {
        :global(body.not-chrome) & {
            transform: scale(0.8);
            transform-origin: left top;
        }

        :global(body.chrome) & {
            zoom: 80%;
        }
    }

    [class*="react-datepicker__header"] {
        background-color: #ffffff;
        margin-bottom: 24px;
        padding: 0;
    }

    [class*="react-datepicker__week"] {
        display: grid;
        grid-template-columns: repeat(7, 26px);
        grid-gap: 4px;
        align-items: center;
        justify-content: center;
    }

    [class*="react-datepicker__day-names"] {
        display: grid;
        grid-template-columns: repeat(7, 26px);
        grid-gap: 4px;
        height: 14px;
        padding-left: 6px;
        padding-right: 6px;
    }

    [class*="react-datepicker__day"] {
        margin: 0;
        font-size: 12px !important;
        font-weight: 600;
        color: #343841;
    }

    [class*="react-datepicker__day-name"] {
        display: flex;
        justify-content: center;
        align-items: center;

        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 11px !important;
        line-height: 130%;
        height: 14px;

        text-align: center;

        color: #89909c;
        margin: 0;
    }

    [class*="react-datepicker__year-text--selected"] {
        background: #343841 !important;
        color: #ffffff !important;
    }

    [class*="react-datepicker__day--selected"] {
        background: #343841 !important;
        color: #ffffff !important;
        border: none !important;
    }

    [class*="react-datepicker__day--today"] {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        border-radius: 80px;
        color: #343841;
        border: 1px solid #89909c;

        &:hover {
            background: #dbdbdb;
            border: none;
        }
    }

    [class*="react-datepicker__day--disabled"] {
        color: #dbdbdb !important;
        cursor: default !important;

        &:hover {
            background-color: transparent;
        }
    }

    [class*="react-datepicker__month-container"] {
        height: 246px;
    }

    [class*="react-datepicker__month"] {
        margin: 0 auto;
    }

    [class*="react-datepicker__year-wrapper"] {
        display: grid;
        grid-template-columns: 58px 58px 58px;
        justify-items: center;
    }

    [class*="react-datepicker__year-text"] {
        padding: 4px 6px;
        width: 58px;
        height: 24px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        color: #000000;
        text-align: center;
    }

    [class*="react-datepicker__day--outside-month"] {
        color: #89909c;

        &:hover {
            cursor: pointer;
            background-color: transparent;
        }
    }

    .header_button {
        position: absolute;
        display: flex;
        height: 20px;
        width: 20px;
        border: none;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;

        &.right {
            top: 140px;
            right: 0;
            background-image: url("/images/arrow_right_black.svg");
            background-color: #fff !important;
        }

        &.left {
            top: 140px;
            left: 0;
            background-image: url("/images/arrow_left_black.svg");
            background-color: #fff !important;
        }
    }
}

.react-datepicker-time__caption {
    display: none !important;
}

.yearDropdown {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 254px;
    height: 286px;
    background-color: #ffffff;
}
