.progressBarContainer {
    color: var(--color);
    background: var(--bg);
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 147px;
    padding: 16px 15px 35px 15px;

    .progressBar {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 0 20px;
        height: 96px;
        background-color: var(--sentiments-mbl-bg);
        border-radius: 5px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        .progressLine {
            flex-grow: 1;
        }

        .infoIcon {
            position: absolute;
            bottom: 14px;
            background-image: url("/images/info.svg");
            background-position: center;
            width: 20px;
            height: 20px;

            filter: invert(64%) sepia(3%) saturate(1134%) hue-rotate(179deg) brightness(88%) contrast(92%);
        }
    }
}

.modalBackdrop {
    background-color: var(--backdrop-bg) !important;
}

.infoContainer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px 16px 0px 0px;

    color: var(--color);
    background: var(--bg);

    .header {
        display: flex;
        padding: 16px 15px 32px 15px;
        justify-content: space-between;
        align-items: center;

        .header__title {
            font-weight: 600;
            font-size: var(--desk-font-size-2-4);
            line-height: 32px;
        }

        .closeIcon {
            position: absolute;
            right: 17px;
            top: 18px;
            background: var(--set-lot-size-mbl-menu-close-icon-bg);
            height: 26px;
            width: 26px;
            border-radius: 13px;
            display: flex;

            &::before {
                margin: auto;
                height: 24px;
                width: 24px;
                content: var(--set-lot-size-mbl-menu-close-icon-url);
            }
        }
    }

    .content {

        .items {
            display: flex;
            flex-direction: column;

            .item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 18px;
                padding: 0px 19px 16px 15px;
                font-weight: 500;
                font-size: var(--desk-font-size-1-4);
                line-height: 20px;

                .greenBlock,
                .redBlock {
                    height: 8px;
                    width: 25px;
                    border-radius: 4px;
                    background-color: #16BB6F;
                }

                .redBlock {
                    background-color: #F25555;
                }

                .text {
                    width: calc(100% - 50px);
                }

                .arrowUpIcon {
                    transform: rotate(-45deg);

                    .arrowIcon {
                        color: var(--sentiments-mbl-color);
                    }
                }

                .arrowDownIcon {
                    transform: rotate(225deg);
                }
            }
        }

        .description {
            display: flex;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-size: var(--desk-font-size-1);
            line-height: 14px;
            color: var(--sentiments-mbl-footer-text-color);
            padding: 8px 15px 0px 15px;
            margin-bottom: 33px;
        }
    }

    &.tablet {
        max-width: 375px;
        max-height: 275px;
        border-radius: 16px;

        bottom: calc(50% - 275px / 2);
        left: calc(50% - 375px / 2);
    }
}