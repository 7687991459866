.container {
    width: 33px;
    height: 18px;
    background: var(--toggle-bg);
    border-radius: 9px;
    padding: 2px;
    display: flex;
    cursor: pointer;

    &.on {
        background: var(--toggle-on-bg);
    }

    &.disabled {
        background: var(--toggle-bg-disabled);
        pointer-events: none;
    }

    .thumb {
        transition: transform 0.3s ease-in-out;
        height: 14px;
        width: 14px;
        border-radius: 7px;
        background: var(--toggle-before-bg);

        &.on {
            transform: translateX(15px);
            background-color: var(--toggle-on-before-bg);
        }
    }

    &.isMobile {
        width: 45px;
        height: 24px;
        border-radius: 12px;
        padding: 3px;
        background-color: var(--app-toggle-mbl-bg);

        &.on {
            background-color: #007BFF;
        }

        .thumb {
            height: 18px;
            width: 18px;
            border-radius: 9px;
            background-color: #FFFFFF !important;

            &.on {
                transform: translateX(21px);
            }
        }
    }
}