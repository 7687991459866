.container {
    position: fixed;
    bottom: 0;
    background: var(--candlesticks-mbl-bg);
    padding: 16px 0 27px 0;
    height: 147px;
    width: 100%;

    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
}

ul.chartTypeList {
    display: flex;
    gap: 10px;
    padding-left: 15px;

    li {
        display: flex;
        flex-direction: column;
        min-width: 90px;
        width: 90px;
        height: 104px;
        background: var(--candlesticks-mbl-bg);
        border: 2px solid var(--candlesticks-mbl-border);
        border-radius: 5px;

        .title {
            font-weight: 500;
            font-size: var(--mbl-font-size-xsm);
            line-height: 14px;
            text-align: center;
            color: var(--candlesticks-mbl-color);
            padding: 16px 4px 0;
        }

        .icon {
            width: 40px;
            height: 40px;
            margin: auto auto 16px;
        }

        .lineIcon {
            content: var(--candlesticks-mbl-line-icon-url);
        }

        .areaIcon {
            content: var(--candlesticks-mbl-area-icon-url);
        }

        .candlesticksIcon {
            content: var(--candlesticks-mbl-candlesticks-icon-url);
        }

        .hlcIcon {
            content: var(--candlesticks-mbl-hlc-icon-url);
        }

        .dotsIcon {
            content: var(--candlesticks-mbl-dots-icon-url);
        }

        .heikinashiIcon {
            content: var(--candlesticks-mbl-heikinashi-icon-url);
        }

        .hollowСandlesticksIcon {
            content: var(--candlesticks-mbl-hollow-candlesticks-icon-url);
        }

        .ohlcIcon {
            content: var(--candlesticks-mbl-ohlc-icon-url);
        }

        .histogramIcon {
            content: var(--candlesticks-mbl-histogram-icon-url);
        }

        .columnsIcon {
            content: var(--candlesticks-mbl-columns-icon-url);
        }

        .lineAnd_dotsIcon {
            content: var(--candlesticks-mbl-line-and-dots-icon-url);
        }
    }

    .active {
        background: var(--candlesticks-mbl-active-bg);
        border: 2px solid #F4F6FB;

        .title {
            color: var(--candlesticks-mbl-active-color);
        }

        .lineIcon {
            content: var(--candlesticks-mbl-line-icon-active-url);
        }

        .areaIcon {
            content: var(--candlesticks-mbl-area-icon-active-url);
        }

        .candlesticksIcon {
            content: var(--candlesticks-mbl-candlesticks-icon-active-url);
        }

        .hlcIcon {
            content: var(--candlesticks-mbl-hlc-icon-active-url);
        }

        .dotsIcon {
            content: var(--candlesticks-mbl-dots-icon-active-url);
        }

        .heikinashiIcon {
            content: var(--candlesticks-mbl-heikinashi-icon-active-url);
        }

        .hollowСandlesticksIcon {
            content: var(--candlesticks-mbl-hollow-candlesticks-icon-active-url);
        }

        .ohlcIcon {
            content: var(--candlesticks-mbl-ohlc-icon-active-url);
        }

        .histogramIcon {
            content: var(--candlesticks-mbl-histogram-icon-active-url);
        }

        .columnsIcon {
            content: var(--candlesticks-mbl-columns-icon-active-url);
        }

        .lineAnd_dotsIcon {
            content: var(--candlesticks-mbl-line-and-dots-icon-active-url);
        }
    }
}