.container {
    margin-top: 10px;
    color: var(--trading-order-info-list-row-color);
    font-weight: 600;
    font-size: var(--desk-font-size-1-4);
    line-height: 130%;
    border: 1px solid #89909c;
    border-radius: 4px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
}

.content {
    border-top: 1px solid #89909c;
}

.pendingOrderToggleLabel {
    font-weight: 600;
    font-size: var(--desk-font-size-xsm1);
    line-height: 14.3px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin: 12px 6px;
    height: 24px;

    > div {
        display: flex;
        align-items: center;

        &:last-child {
            width: 70%;
            justify-content: flex-start;
        }
    }
}

.expirationCheckbox {
    margin-right: 6px;
    min-width: 18px;
}

.expirationDateInput {
    max-width: none;
    width: 100%;
}

.datePickerWrapper {
    width: 100%;
}

.operationCategoryTextInput_dark,
.operationCategoryTextInput_light {
    input {
        background-color: var(--text-input-bg-dark);
        color: var(--text-input-color-dark);
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 130%;
        padding: 4px 40px 3px 8px;
        border-radius: 2px;
    }
}

.operationCategoryTextInput_light {
    input {
        background-color: var(--text-input-bg-light);
        border: 1px solid transparent;
        color: var(--text-input-color);
    }
}

.pendingOrderPriceInput {
    font-size: 13px;
}
