.footer {
    display: flex;
    height: var(--footer-height);
    background: var(--footer-bg);
    justify-content: space-between;
    padding: 0 12px 0 8px;
    position: relative;
    z-index: 999 !important;
    border-top: 2px solid var(--footer-border);

    @media (max-width: 1366px) {
        height: 46px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
}

.overlayed {
    pointer-events: none;
    z-index: 1301 !important;
    background-color: var(--bg);
}

.drawer {
    z-index: 1300 !important;
    height: calc((var(--vh, 1vh) * 100));
}

.footerLeft {
    display: flex;
    justify-self: start;
}

.footerRight {
    display: flex;
    justify-self: end;
}

.userInfo {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInfoItem {
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 2.1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //padding: 0 16px;
    gap: 4px;
    border-right: 1px solid var(--footer-separator-border);

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        border: none;
    }
}

.userInfoLabel {
    color: var(--footer-label-color);
    font-weight: 700;
    font-size: var(--desk-font-size-1-3);
    line-height: 130%;

    @media (max-width: 1366px) {
        font-size: 10px;
        line-height: 13px;
    }

    @media screen and (max-width: 1024px) {
        font-size: var(--desk-font-size-08);
    }
}

.userInfoValue {
    min-width: 92px;
    color: var(--footer-value-color);
    font-weight: 700;
    font-size: var(--desk-font-size-1-3);
    line-height: 130%;
    height: 20px;
    min-height: 20px;

    @media (max-width: 1366px) {
        font-size: 12px;
        line-height: 12px;
    }

    @media screen and (max-width: 1024px) {
        font-size: var(--desk-font-size-1);
    }
}

.footer_wrp {
    display: flex;
    flex-direction: column;
}
