.container {
    display: flex;
    padding: 9px 0;
    background: #4C515E;
    width: 100%;
    height: 58px;

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        

        &::before {
            height: 24px;
            width: 24px;
            content: url("/images/moon.svg");
        }
    }

    .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;

        &.no_open_time {
            justify-content: center;
        }
    }
}
