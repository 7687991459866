.container {
    position: relative;
    border-right: 2px solid var(--layout-separator-color);
    max-width: 448px;

    @media screen and (max-width: 1366px) {
        :global(body.not-chrome) & {
            transform: scale(0.8);
            transform-origin: left top;
            height: calc(100% + 1px);
        }
        :global(body.chrome) & {
            zoom: 80%;
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1366px) {
            margin-right: -90px;
            height: calc(100% + 198px);
        }

        @-moz-document url-prefix() {
            @media (max-width: 1366px) {
                height: calc(100% + 298px);
            }
        }

        @media (max-width: 1260px) {
            margin-right: -120px;
            height: calc(100% + 346px);
        }

        @media (max-width: 1130px) {
            margin-right: -145px;
            height: calc(120% + 274px);
        }

        @-moz-document url-prefix() {
            @media (max-width: 1130px) {
                height: calc(100% + 298px);
            }
        }

        @media (max-width: 920px) {
            margin-right: -166px;
            height: calc(150% + 130px);
        }

        @media (max-width: 800px) {
            margin-right: -145px;
        }
    }
    // ZOOOM
    @-moz-document url-prefix() {
        @media (max-width: 1366px) {
            height: calc(100% + 254px);
        }
    }

    @media (max-width: 1260px) {
        :global(body.not-chrome) & {
            transform: scale(0.7);
            transform-origin: left top;
            height: calc(100% + 1px);
        }
        :global(body.chrome) & {
            zoom: 70%;
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1260px) {
            height: calc(100% + 412px);
        }
    }

    @media (max-width: 1130px) {
        :global(body.not-chrome) & {
            transform: scale(0.65);
            transform-origin: left top;
            height: calc(100% + 1px);
        }
        :global(body.chrome) & {
            zoom: 65%;
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1130px) {
            height: calc(100% + 520px);
        }
    }

    @media (max-width: 920px) {
        :global(body.not-chrome) & {
            transform: scale(0.6);
            transform-origin: left top;
            height: calc(100% + 1px);
        }
        :global(body.chrome) & {
            zoom: 60%;
        }
    }
}

.searchResultPlaceholder {
    font-family: var(--main-font-family);
    font-size: var(--desk-font-size-1-3);
    line-height: 15.6px;
    font-weight: 600;
    color: var(--asset-list-search-title-color) !important;
    padding: 0 0px 15px 15px;
    width: 180px;
}

.expanded {
    max-width: 830px;
    width: 780px;
}

.collapsed {
    //width: 448px;
}

.overlayed {
    pointer-events: none;
    z-index: 1305;
    background-color: var(--trade-order-section-overlayed-bg);
}

.toolbar {
    display: flex;
    width: 100%;
    height: var(--header-toolbar-height);
    padding: 8px 0 8px 8px;

    background: var(--bg);
    z-index: 2;
}

.activeSearch {
    input {
        border-radius: 32px;
        outline: 4px solid #4c515e !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow: hidden;
    max-height: calc(100vh - 114px);

    @media (max-width: 1366px) {
        height: calc(100% - 56px);
        max-height: unset;
    }
}

.expandButton {
    background-position: 50% 62%;
    cursor: pointer;
    width: 26px;
    height: 30px;
    margin: 0 8px 0 12px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-image: var(--asset-list-expand-icon-url);

    &:hover {
        background-image: var(--asset-list-expand-icon-url-hover);
    }

    img {
        display: block;
        width: 16px;
    }

    .expandIconHover {
        display: none;
    }

    &:hover {
        .expandIcon {
            display: none;
        }

        .expandIconHover {
            display: block;
        }
    }
}

.expandButton_disabled {
    cursor: initial;

    &:hover {
        background-image: var(--asset-list-expand-icon-url);
    }
}

.tableWrap {
    height: 100%;
    width: 100%;
    padding-top: 42px;
    margin: 0;

    @-moz-document url-prefix() {
        @media (max-width: 1366px) {
            height: calc(100vh + 152px);
        }

        @media (max-width: 1260px) {
            height: calc(100vh + 300px);
        }

        @media (max-width: 1260px) {
            height: calc(100vh + 400px);
        }
    }
}

.isSearchOpen {
}

.categoryListWrap {
    position: absolute;
    display: flex;
    align-items: center;
    top: var(--header-toolbar-height);
    width: 100%;
    z-index: 2;
    background: var(--bg);
    height: 42px;
    padding-right: 8px;

    @media screen and (max-width: 479px) {
        position: unset;
        padding: 16px;
    }

    .categoryList {
        position: relative;
        min-height: var(--asset-categories-height);
        min-width: 100%;

        @media screen and (max-width: 479px) {
            min-height: var(--asset-categories-mbl-height);
            margin-top: 0.4rem;
        }

        :global(.MuiTabs-flexContainer) {
            @media screen and (max-width: 479px) {
                padding: 0 1rem;
            }

            gap: 8px;
            padding: 0 8px;
            width: 100%;
            margin: 0;
        }

        :global(.MuiTabScrollButton-horizontal) {
            @media screen and (max-width: 479px) {
                height: var(--asset-categories-mbl-height);
            }

            position: absolute;
            height: var(--asset-categories-height);
            z-index: 2;
            opacity: 1;

            :global(.MuiSvgIcon-root) {
                font-size: var(--desk-font-size-1-4);
                width: 2em;
                height: 2em;
            }

            &:global(.Mui-disabled) {
                opacity: 0;
                pointer-events: none;
            }

            &:first-child {
                left: 0;
                background: var(--asset-list-category-nav-prev-bg);
            }

            &:last-child {
                right: 0;
                background: var(--asset-list-category-nav-next-bg);
            }
        }

        .categoryListItem {
            @media screen and (max-width: 479px) {
                min-height: var(--asset-categories-mbl-height);
                font-weight: 500;
                font-size: var(--mbl-font-size-xs);
                line-height: 16px;
                padding: 4px 9px;
                color: var(--asset-list-category-hover-color);
            }

            font-family: var(--main-font-family);
            font-size: var(--desk-font-size-1-5);
            line-height: 1.2;
            font-weight: 600;
            border-radius: 80px;
            text-transform: none;
            letter-spacing: normal;
            height: 26px;
            padding: 4px 8px;
            min-height: var(--asset-categories-height);
            max-height: var(--asset-categories-height);
            min-width: 0;
            background-color: var(--asset-list-category-bg);
            color: var(--asset-list-category-color);
            border: 1px solid var(--asset-list-category-border);

            &:hover {
                background-color: var(--asset-list-category-hover-bg);
                color: var(--asset-list-category-hover-color);
                border: 1px solid transparent;
            }

            &.categoryListItemActive {
                background-color: var(--asset-list-category-active-bg);
                color: var(--asset-list-category-active-color);
                border: 1px solid transparent;

                &:hover {
                    color: var(--asset-list-category-active-color);
                }
            }

            &.listItemExpanded {
                flex-grow: 1;
            }
        }
    }
}
